$lms-form-invalid-color: #f64e60;
$lms-form-valid-color: #1bc5bd;

// upload image form element
.lms-image-upload-input {
  display: flex;
}

.lms-image-upload-area {
  padding: 1rem;
  border: 1px dashed #ddd;
  width: 100%;
  height: 57.9px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #999;
  border-radius: 0.42rem;

  &:hover {
    color: #333;
  }
}

.lms-upload-icon {
  margin-right: 1rem;
}

.lms-image-uplaod-preview {
  width: 100%;
  height: 120px;
  max-width: 100%;
  border-radius: 0.42rem;
  object-fit: cover;
}

.lms-image-form-delete-btn {
  position: relative;
  max-width: 50%;
  min-width: 8rem;
  height: 120px;
  margin-right: 1rem;
  border-radius: 0.42rem;
  border: 1px solid #ddd;
  background-color: transparent;
  padding: 0;

  &:hover {
    .lms-image-delete-icon {
      opacity: 0.9;
    }
  }
}

.lms-image-delete-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: -1px;
  background-color: $lms-form-invalid-color;
  border-radius: 0.42rem;
  color: #fff;
  opacity: 0;
}

// advanced select
.lms-advanced-select {
  &.is-invalid {
    div[class$="-control"] {
      border-color: $lms-form-invalid-color;
    }
  }

  &.is-valid {
    div[class$="-control"] {
      border-color: $lms-form-valid-color;
    }
  }
}

.lms-form-info-text {
  font-size: 11.7px;
}
.lms-outline-none {
  outline: none !important;
}

.popover {
  z-index: 99999 !important;
}

.lms-btn-primary {
  background: #a289d6 !important;
  color: white !important;
}
.lms-btn-primary.disabled {
  background: #d0c4eb !important;
}

.lms-bg-pink-soft {
  background: #f6e3f6 !important;
}

.lms-bg-pink-soft-hover:hover {
  background: #f5b7f5 !important;
}

.lms-bg-pink {
  background: #f5b7f5 !important;
}
