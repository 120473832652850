.lms-campus-instutition-list,
.lms-employee-list {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  flex-wrap: wrap;
}

.lms-employee-list-item {
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 0.35rem;
  flex-basis: 150px;
  flex-grow: 1;

  .lms-employee-id {
    font-size: 0.875rem;
    display: block;
    margin-bottom: 0.75rem;
  }

  .lms-employee-name {
    font-weight: 700;
    display: block;
  }

  .lms-employee-remove-btn {
    float: right;
    background-color: transparent;
    border: none;
    color: #ccc;
    padding: 0;

    &:hover {
      color: #333;
    }
  }
}

// kampüs row açılınca gelen kurum listesi
.lms-campus-instutition-item {
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 0.35rem;
  flex-basis: 150px;
  flex-grow: 1;
}

// ders içerik menüsü
.lms-course-content-menu-list {
  padding-left: 0;
  list-style-type: none;
  background-color: #f8f8f8;
  padding: 0.5rem 0;
  border-radius: 0.42rem;
  border: 1px solid #eee;

  li {
    display: flex;
    padding: 0 1rem;
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom-width: 0;
    }
  }

  .lms-course-content-menu-item {
    display: flex;
    background-color: transparent;
    border: none;
    width: 100%;
    text-align: left;
    align-items: center;
    padding: 0.75rem 0.25rem;
    color: #666;
    transition: color 0.3s ease;

    &.active,
    &:hover {
      color: #000;
    }

    .mdi {
      margin-right: 0.5rem;
    }
  }

  .lms-course-content-item-delete-btn {
    background-color: transparent;
    border: none;
    font-size: 0.5rem;

    .mdi {
      width: 1rem !important;
      height: 1rem !important;
    }

    &:hover {
      color: #f64e60;
    }
  }

  // drag mode
  &.menu-item-dragging {
    padding-bottom: 3.5rem;
  }

  .lms-course-draggable-item {
    padding-left: 0.75rem;

    &.menu-item-dragging {
      border-width: 1px;
      border-style: dotted;
      border-color: #ccc;
    }
  }

  .lms-course-draggable-item-drag-icon {
    margin-top: 0.75rem;
    margin-right: 0.25rem;
  }
}

// question list actions
.lms-list-action-btn {
  background-color: $primary;
  border: none;
  color: $white;
  width: 3rem;
  height: 3rem;
  display: block;
  border-radius: 0.42rem;
  margin-bottom: 0.5rem;

  &:disabled {
    opacity: 0.3;
  }
}

// atama ekranı
.lms-assign-management-container {
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
}

.lms-assign-list-group-container {
  flex: 1;

  .MuiSwitch-colorSecondary.Mui-checked {
    color: $white !important;
  }
}

.lms-assign-list-group {
  list-style-type: none;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: $border-radius;
  background-color: $white;
  height: 16rem;
  overflow: auto;
  margin: 0.5rem 0 1rem 0;

  li:last-child {
    label {
      margin-bottom: 0;
    }
  }

  .lms-assign-list-item-label {
    margin-left: 0.5rem;
  }

  .lms-assign-list-input {
    vertical-align: middle;
  }
}

.lms-assign-list-group-title {
  font-weight: 600;
  padding: 0.375rem 0;
}

.lms-assign-list-group-custom-title {
  display: flex;
  align-items: center;

  .MuiSwitch-root {
    margin: 0.5rem 0;
  }
}

.lms-assign-manager-filter-input {
  height: 2.2rem !important;
}

.lms-assign-manager-filter-btn {
  padding: 0.25rem !important;
}

.lms-list-item {
  border-bottom: "1px solid #ECEEF9";
  padding: "8px 0px";
}

.lms-list-item-icon {
  width: 42px;
  height: 42px;
  background: #eceef9;
  border-radius: 64px;
  font-size: 11px;
}

.lsm-complated-course-label {
  color: "#969696";
  font-size: 9px;
  font-weight: 700px;
  margin-top: -5px;
}
// .parent-formatter.open {
//   .icon-editable {
//     display: none;
//   }
// }

.lms-student-exam-card {
  background: #ffe0e6;
  border-radius: 18px;
  color: #711021;
}
.lms-student-exam-card.active {
  background: #ff8fa2;
  color: white;
}
.lms-exam-form-container {
  background: #4e42a9;
  border-radius: 18px;
  padding: 2px;
}
.lms-exam-btn {
  background: transparent;
  border-radius: 18px;
  color: white;
  border: none;
  padding: 6.5px 13px;
  transition: all 0.3s ease;
}
.lms-exam-btn:hover {
  background: #968fd4;
}
.lms-exam-btn.active {
  background: #9087e5;
}

.accordion-header {
  button {
    background: transparent;
    border: none;
    width: 100%;
    margin: 0px;
    padding: 0px;
  }
}
.exam-result-nav {
  .active {
    background: #9087e5 !important;
  }
}
.exam-detail-nav {
  background: #84dcca;
  border-radius: 16px;
  overflow: hidden;
  .active {
    background: #9ff3e1 !important;
  }
}

.lms-nav-container {
  background: #f2faff !important;
  border: 1px solid #c2c2c2 !important;
  border-left: none !important;
  border-right: none !important;
  display: flex !important;
  .active {
    background: transparent !important;
    color: #7738c7 !important;
    border-bottom: 1px solid #7738c7 !important;
    border-radius: 0px !important;
  }
}

.basic-table {
  background: white;
}
.basic-table:nth-child(odd) {
  background: #f3f3f3;
}
.basic-table:hover {
  background-color: #e2e2e2;
}

.small-h2 {
  .accordion-item {
    h2 {
      font-size: 16px !important;
      padding: 0px !important;
      margin: 0px !important;
    }
  }
}

.stick-program {
  width: 10px;
  height: 30px;
  background: #2a8df8;
  position: absolute;
  top: -10px;
  border-radius: 4px;
  border: 1px solid black;
  z-index: 1;
  border-top-right-radius: 0px;
}

.stick-program::before {
  content: "";
  width: 4px;
  height: 10px;
  position: absolute;
  background-color: black;
  border-top-right-radius: 4px;
  left: 8px;
  z-index: -1;
  top: -1px;
}

.stick-program-shadow {
  width: 10px;
  height: 30px;
  background: #2a8df8;
  position: absolute;
  top: -10px;
  border-radius: 4px;
  border: 1px solid black;
  z-index: 1;
  border-top-right-radius: 0px;
}

.stick-program-shadow::before {
  content: "";
  width: 4px;
  height: 10px;
  position: absolute;
  background-color: black;
  border-top-right-radius: 4px;
  left: 8px;
  z-index: -1;
  top: -1px;
}

.stick-program-shadow::after {
  content: "";
  width: 4px;
  height: 7px;
  position: absolute;
  background-color: #ffc6ab;
  border-top-right-radius: 4px;
  left: 12px;
  z-index: -1;
  bottom: 20px;
}

.self-id-item {
  background: #f9f9f9 !important;
  border: solid #48454b 1px !important;
}

.self-id-item-extra {
  background: #f9f9f9 !important;
  border: solid #2a8df8 1px !important;
}

.transfer-table {
  display: flex;
  flex-direction: column !important;
}

.transfer-table thead {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}

.transfer-table thead tr {
  display: flex !important;
  width: 100% !important;
}

.transfer-table th {
  width: 100%;
  text-align: center !important;
}
