.lms-top-menu {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    float: left;
    padding: 1rem;
    font-size: 1rem;
    a {
      color: #3f4254;
      font-weight: 400;
    }
    border-radius: $border-radius;
    &:hover {
      background-color: #f3f6f9;
      a {
        color: #3699ff;
      }
    }
  }
}

.user-profile-img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.lms-backdrop {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10;
}

.lms-nav {
  .active {
    background: #a289d6 !important;
  }
}

.lms-nav-bar {
  .active {
    background: #f5f6fd !important;
    color: #755cbb !important;
  }
  .nav-link:hover {
    color: #755cbb !important;
  }
}

.lms-sub-nav-bar {
  background-color: #f2faff !important;
  border-bottom: solid #c2c2c2 1px !important;
  border-top: solid #c2c2c2 1px !important;
  .active {
    background: transparent !important;
    color: #7738c7 !important;
    border-bottom: solid #7738c7 2px !important;
    text-align: center !important;
  }
  .nav-link:hover {
    color: #755cbb !important;
  }
}

@media screen and (min-width: 991px) {
  .position-lg-relative {
    position: relative !important;
  }
}
