$primary: #0355f9;

$blue: #1ab3f4;
$blue-tint: #cbefff;
$blue-dark: #6d8cc5;

$green: #4fe366;
$green-tint: #e4f9e8;
$green-dark: #1ea032;

$red: #f05154;
$red-tint: #fdefef;
$red-dark: #e32125;

$yellow: #ffca29;
$yellow-tint: #fdf6df;
$yellow-dark: #e9b000;

$orange: #ff9c45;
$orange-tint: #fff1e4;
$orange-dark: #ee7032;

$purple: #b210eb;
$purple-tint: #eebcff;
$purple-dark: #9412aa;

$lms-primary: #7738c7;
$lms-light-primary: #dedeff;
$lms-dark: #48454b;
$lms-muted: #79798a;
