.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 1;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

.max-height-500 {
  max-height: 500px !important;
}

.form-control {
  border-color: hsl(0, 0%, 80%);
}

.accordion .card .card-header {
  border-bottom: 1px solid #ebedf3 !important;
  background-color: transparent !important;
}

.CenterAlign {
  top: 25%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -25%);
}

.Overlay {
  background-color: rgba(0, 0, 0, 0.55);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: none;
  z-index: 5;
}

.Overlay.Show {
  display: block;
}

.Modal {
  position: fixed;
  right: -200vh;
  bottom: 24%;
  background-color: #fff;
  width: 50%;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-out;
  z-index: 10;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.Modal.Show {
  right: 0;
}

.Close {
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: transparent;
  border: 0;
  font-size: 18px;
}

.MuiStepLabel-iconContainer svg {
  width: 1.5em !important;
  height: 1.5em !important;
}

.MuiStepContent-root {
  margin-left: 15px !important;
}

.MuiStepConnector-root {
  margin-left: 15px !important;
}

.mega-menu::before {
  content: " ";
  position: absolute;
  background: #fff;
  width: 12px;
  height: 12px;
  left: 23px;
  top: -6px;
  transform: rotate(45deg);
}
