.replica {
  h1 {
    font-weight: bold;
    color: white;
    margin: 6px 0 12px;
    font-size: 12px;
    font-weight: 700;
  }

  header {
    border-bottom: 1px solid var(--divider);
    position: relative;
  }

  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .card-list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }

  .card-view {
    position: relative;
    padding: 25px;
    height: 300px;
  }

  .card-view-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    // pointer-events: none;
  }

  .card-view-container.open-modal {
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 1;
    overflow: hidden;
    padding: 40px 0;
  }

  .card-view-close {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9999;
    cursor: pointer;
    border-radius: 100%;
  }

  .card-open-modal-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .card-image-container {
    // position: absolute;
    // top: 0;
    // left: 0;
    height: inherit;
    position: relative;
    overflow: hidden;
    // height: 420px;
    // width: 100vw;

    background: linear-gradient(316.53deg, #5397f7 7.31%, #ffccf4 101.15%);
    box-shadow: 0px 16px 22px rgba(87, 154, 248, 0.5);
    border-radius: 28px;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .open-modal .card-image-container,
  .open-modal .title-container {
    z-index: 1;
  }

  .title-container {
    position: absolute;
    top: 15px;
    left: 15px;
    max-width: 300px;
  }

  .open-modal .title-container {
    top: 30px;
    left: 30px;
  }

  h2 {
    color: #fff;
    margin: 0;
    font-size: 11px;
    font-weight: 700;
  }

  .category {
    color: #fff;
    font-size: 14px;
  }

  .overlay {
    z-index: 1;
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    // will-change: opacity;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    overflow: scroll;
  }

  .content-container {
    padding: 460px 35px 35px 35px;
    width: 100%;
  }

  p {
    color: #9d9ca1;
    font-size: 20px;
    line-height: 28px;
  }

  @media only screen and (max-width: 800px) {
    // .card-view {
    //   flex: 0 0 50%;
    //   max-width: 50%;
    // }
  }

  @media only screen and (max-width: 600px) {
    // .card-view {
    //   flex: 1 0 100%;
    //   max-width: 100%;
    //   padding: 10px;
    //   padding-left: 0;
    //   padding-right: 0;
    // }

    .card-view-container.open-modal {
      padding: 0;
    }
  }
}

.lms-message-modal {
  top: 150px;
  bottom: 90px;
  right: 60px;
  width: 580px;
  z-index: 99;
  overflow: hidden;
  position: fixed;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  background: white;
}

.lms-animated-card-modal-overlay {
  z-index: 1;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  overflow-y: auto;
}

.lms-animated-card-modal-content {
  position: sticky;
  height: fit-content;
  width: fit-content;
  // margin: 150px auto !important;
  border-radius: 10px;
}

.custom-modal-content {
  width: 100% !important;
}
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .custom-modal-content {
    width: 90% !important;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .custom-modal-content {
    width: 60% !important;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .custom-modal-content {
    width: 50% !important;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .custom-modal-content {
    width: 35% !important;
  }
  .custom-modal-content.lg {
    width: 55% !important;
  }
}
