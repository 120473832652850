.headerContainer {
  height: 40px;
  width: 100%;
  position: fixed;
  z-index: 10;
}
.subHeader {
  height: 4%;
}

.studentDetailMenu {
  height: 90%;
  .top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 40%;
  }
  .bottom {
    position: relative;
    height: 60%;
  }
}

.scrollable-content-view {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.tahsilatDot {
  color: #4fe366;
}

.taksitDot {
  color: #3498db;
}

.activate-card {
  width: 35%;
  font-size: 22px;
  text-align: center;
  background: #ebedf9;
  color: #5a32b0;
}

.activate-input {
  font-size: 22;
  text-align: center;
  outline: none;
  color: #5a32b0;
  border: solid #5a32b0 2px;
  width: 25%;
  padding: 10px 20px;
  border-radius: 8px;
  background: #ebedf9;
  font-weight: 500;
}
