$neu-white: #f0f0f3;
$neu-orange: #f9a826;
$neu-gray: #808b9f;
$neu-purple: #735ff2;

.layout-full-screen {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1130;
  overflow: hidden;
}

.layout-bottom-menu-mini {
  position: absolute;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: block;
  margin: 0 auto;
  top: 8px;
  left: 8px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(205, 210, 255, 1) 100%);
  box-shadow: inset 0px 0px 4px rgba(39, 97, 210, 0.25);
  filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.25));
}

.layout-bottom-menu {
  position: absolute;
  border-radius: 8px;
  width: fit-content;
  height: 60px;
  display: block;
  margin: 0 auto;
  bottom: 20px;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(205, 210, 255, 1) 100%);
  box-shadow: inset 0px 0px 4px rgba(39, 97, 210, 0.25);
  filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.25));
}
.layout-active-item {
  width: 6px;
  height: 6px;
  border-radius: 16px;
  display: block;
  margin: 0 auto;
}

.layout-top-menu {
  height: 22px;
  background-color: #ede7f6;
  width: 100vw;
}

.layout-logo {
  color: #5a32b0;
  font-weight: 700;
}

$sizes: 0, 20, 30, 40, 60, 70, 80, 100;

@each $size in $sizes {
  .layout-w-#{$size} {
    width: #{$size}#{"%"};
  }
}

.layout-dashboard-middle {
  height: calc(100vh - 22px);
}

$button-metric: 3.8rem;

.layout-menu-button {
  z-index: 20;
  width: $button-metric;
  height: $button-metric;
  border: 0px solid;
  margin: 4px;
  padding: 0;
  border-radius: 4px;
  position: relative;
  background: transparent;
  img {
    width: $button-metric;
    height: $button-metric;
  }
  margin-bottom: 8px;
}

.layout-animation-transition {
  transition: all 1s ease-in-out;
}

.layout-radial-item {
  border-radius: 120px;
  width: 64px;
  height: 64px;
  display: block;
}

.layout-explorer-button {
  background: $neu-purple;
  color: #f9a826;
  font-weight: 700;
  font-size: 1.2rem;
  border-radius: 36px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  &:hover {
    color: #f9a826 !important;
  }
}

.layout-bg-white {
  background-color: $neu-white;
}
.layout-bg-orange {
  background-color: $neu-orange;
}

.layout-gradient-bg-white {
  background: linear-gradient(180deg, #fafafa 0%, #eceff1 100%);
  box-shadow: inset 0px -19px 28px rgba(207, 229, 255, 0.72), inset 0px 0px 3px rgba(0, 0, 0, 0.25) !important;
}

.layout-gradient-bg-orange {
  background: linear-gradient(180deg, #f9a322 0%, #fbc02b 100%);
  box-shadow: inset 0px -15px 18px #f57f17 !important;
}

.layout-gradient-bg-blue {
  background: linear-gradient(180deg, #2196f3 0%, #90caf9 100%);
  box-shadow: inset 0px -19px 28px rgba(207, 229, 255, 0.72), inset 0px 0px 3px rgba(0, 0, 0, 0.25) !important;
}

.layout-gradient-bg-purple {
  background: linear-gradient(180deg, #311a92 0%, #6335b6 100%);
  box-shadow: inset 0px -19px 28px rgba(207, 229, 255, 0.72), inset 0px 0px 3px rgba(0, 0, 0, 0.25) !important;
}

.layout-gradient-bg-light-purple {
  background: linear-gradient(180deg, #fafafa 0%, #eceff1 100%);
  box-shadow: inset 0px -19px 28px #7b54cea8, inset 0px 0px 3px rgba(0, 0, 0, 0.25) !important;
}

.layout-gradient-bg-light-blue {
  background: linear-gradient(180deg, #fafafa 0%, #eceff1 100%);
  box-shadow: inset 0px -19px 28px #558fc8, inset 0px 0px 3px rgba(0, 0, 0, 0.25) !important;
}

.layout-gradient-bg-pink {
  background: linear-gradient(180deg, #ec407a 0%, #d82360 100%);
  box-shadow: inset 0px -19px 24px rgba(255, 158, 193, 0.72), inset 0px 0px 3px rgba(0, 0, 0, 0.25) !important;
}

.layout-speech-baloon {
  padding: 18px;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  border-radius: 28px;
}

.layout-text-prime {
  color: $neu-orange;
}

.layout-text-secon {
  color: $neu-gray;
}

.layout-img {
  object-fit: contain;
}

.layout-animation-card {
  background: $neu-white;
  border: 0px solid transparent;
  // background: #f0f0f3;
  // box-shadow: -2.17893px -2.17893px 6.5368px #ffffff,
  //   2.17893px 2.17893px 6.5368px rgba(174, 174, 192, 0.4);

  background: linear-gradient(180deg, rgba(255, 255, 255, 100) 0%, #e2e4f5 100%);
  box-shadow: inset 0px 0px 4px rgba(39, 97, 210, 0.25);
  border-radius: 8px;
  cursor: pointer;
}

.layout-animation-card-free {
  transition: all 2s;
  position: absolute;
  //left: 50%;
  //height: 50%;
  top: 80px;
  z-index: 200;
}

.layout-scroll-none {
  overflow: hidden;
}

.layout-neu-box {
  background: #f0f0f3;
  background: linear-gradient(180deg, rgba(255, 255, 255, 100) 0%, rgba(237, 238, 253, 0.36) 100%);
  box-shadow: inset 0px 0px 4px rgba(39, 97, 210, 0.25);
  border-radius: 5px;
}

.layout-point {
  background-color: #5a32b0;
  width: 6px;
  height: 6px;
  border-radius: 120px;
}

@keyframes opacity-anim {
  0% {
    opacity: 0;
    display: block;
    // transform: scale(0);
  }
  100% {
    opacity: 1;
    // transform: scale(1);
  }
}

.layout-opacity-initial {
  // transform: scale(0);
  display: none;
}

.layout-opacity-anim {
  animation: opacity-anim 400ms ease normal;
}

@keyframes detail-panel-anim {
  0% {
    transform: scale(0.1);
    left: var(--mouse-x);
    top: var(--mouse-y);
    transform: translate(0%, 0%);
  }
  100% {
    transform: scale(1);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.layout-detail-anim {
  animation: detail-panel-anim 1s ease normal;
}

.switch {
  width: 48px;
  height: 26px;
  border-radius: 100px;
  padding: 4px;
  display: flex;
  cursor: pointer;
}

.switch.on {
  background-color: $neu-purple;
  justify-content: flex-end;
}

.switch.off {
  background-color: #dddddd;
  justify-content: flex-start;
}

.switch div {
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border-radius: 200px;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.02);
}

.App {
  text-align: center;
  display: block;
  width: 100%;
  height: 100vh;
  position: relative;
  bottom: 0;
  overflow: hidden;
}

.transition {
  transition: all 1s ease-in-out;
}

.width-0 {
  width: 0%;
}

.width-40 {
  width: 40%;
}

.width-60 {
  width: 60%;
}

.width-30 {
  width: 30%;
}

.width-70 {
  width: 70%;
}

.width-100 {
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .responsive-width {
    width: 100% !important;
  }
}

.layout-right-menu-button {
  background-color: transparent;
}

.layout-student-course {
  border-radius: 20px 20px 0px 0px !important;
}

.layout-dashboard-height {
  height: calc(100vh - 136px) !important;
}

.layout-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 320px;
  background: #fff;
}
