.achievement-star {
  width: 26px;
  min-width: 26px;
  height: 26px;
  min-height: 26px;
}

.achievement-badge {
  width: 49px;
  min-width: 49px;
  height: 49px;
  min-height: 49px;
  transform: rotateX(180deg);
  // box-shadow: ;
  // filter: drop-shadow(9px 9px 0px 0px rgba(208, 130, 248, 1));
  filter: drop-shadow(3px -3px 0px black);
}

.channel-button {
  font-weight: 700;
  border: solid black 2px;
  box-shadow: 9px 9px 0px 0px #969696 !important;
  cursor: pointer;
}

.channel-button.active {
  background: #9cffbe !important;
  box-shadow: 9px 9px 0px 0px black !important;
}

.channel-button:hover {
  background: #9cffbe !important;
  box-shadow: 9px 9px 0px 0px black !important;
}

.lms-shadow {
  box-shadow: 9px 9px 0px 0px #969696 !important;
}

.lms-shadow.active {
  box-shadow: 9px 9px 0px 0px black !important;
}

.lms-active-header {
  background-color: #d082f8 !important;
  border-bottom: solid black 2px;
}

.bold-border {
  border: solid black 2px;

  .active {
    font-weight: 700 !important;
    color: black !important;
  }
}

.bold-border-bottom {
  border-bottom: solid black 2px;
}

.lms-shadow-primary {
  box-shadow: 9px 9px 0px 0px rgba(208, 130, 248, 1);
}
.lms-shadow-primary-sm {
  box-shadow: 4.5px 4.5px 0px 0px rgba(208, 130, 248, 1);
}

.lms-shadow-primary-sm-avatar {
  box-shadow: 0px 2.5px 0px 0px rgba(208, 130, 248, 1);
}

.lms-shadow-blue-sm {
  box-shadow: 4.5px 4.5px 0px 0px #2a8df8;
}

.lms-shadow-green-sm {
  box-shadow: 4.5px 4.5px 0px 0px #9cedb8;
}

.lms-shadow-secondary {
  box-shadow: 9px 9px 0px 0px #ffc6ab;
}
.lms-shadow-secondary-sm {
  box-shadow: 4.5px 4.5px 0px 0px #ffc6ab;
}

.lms-shadow-dark {
  box-shadow: 9px 9px 0px 0px #48454b;
}

.lms-shadow-dark-sm {
  box-shadow: 4.5px 4.5px 0px 0px #48454b;
}

.lms-border-black {
  border: solid black 1px !important;
}

.lms-border-b-black {
  border-bottom: solid black 1px !important;
}
