.navi {
  padding: 0;
  margin: 0;
  display: block;
  list-style: none;
  .navi-item {
    padding: 0;
    display: block;
    list-style: none;
    .navi-link {
      -webkit-transition: all 0.15s ease;
      transition: all 0.15s ease;
      color: #3f4254;
      font-size: 1rem;
      border-radius: 0.42rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.75rem 1.5rem;
      &.active {
        background-color: #f3f6f9;
        -webkit-transition: all 0.15s ease;
        transition: all 0.15s ease;
        color: #3699ff;
      }
      &:hover {
        background-color: #f3f6f9;
        -webkit-transition: all 0.15s ease;
        transition: all 0.15s ease;
        color: #3699ff;
      }
    }
  }
}

.lms-track-light-image {
  img {
    min-width: 200px;
    max-width: 250px;
  }
}

.lms-kazanim-list {
  display: flex !important;
  @media screen and (max-width: 991px), (max-width: 767px) {
    display: block !important;
  }
}

.track-light-information-image {
  @media screen and (max-width: 991px), (max-width: 767px) {
    width: 100% !important;
  }
}

.track-light-kazanim-group-heigth {
  height: calc(100vh - 185px) !important;
}

.navi.navi-active .navi-item .navi-link.active {
  background-color: #f3f6f9;
}

.ogrenci-kazanim-cevap {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 262px !important;
  width: 200px !important;
  padding-top: 50px;
}

.empty-cevap {
  background-image: url("../../../public/media/track-light/empty-light.png"); /* The image used */
}

.green-cevap {
  background-image: url("../../../public/media/track-light/green-light.png"); /* The image used */
}
.yellow-cevap {
  background-image: url("../../../public/media/track-light/yellow-light.png"); /* The image used */
}
.red-cevap {
  background-image: url("../../../public/media/track-light/red-light.png"); /* The image used */
}

.mygoal-empty-cevap {
  background-image: url("../../../public/media/track-light/mygoal-empty-light.png"); /* The image used */
}

.mygoal-green-cevap {
  background-image: url("../../../public/media/track-light/mygoal-green-light.png"); /* The image used */
}
.mygoal-yellow-cevap {
  background-image: url("../../../public/media/track-light/mygoal-yellow-light.png"); /* The image used */
}
.mygoal-red-cevap {
  background-image: url("../../../public/media/track-light/mygoal-red-light.png"); /* The image used */
}

.red-cevap-clickable-height {
  height: 64px;
}

.yellow-cevap-clickable-height {
  height: 54px;
}

.green-cevap-clickable-height {
  height: 60px;
}
