.pageSide {
  width: 60%;
  z-index: 1;
}

.questionListSide {
  width: 40%;
  z-index: 10;
  // background-color: red;
}

.pdfLoading {
  z-index: 50;
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
}

.questionView {
  overflow: auto;
  max-height: 270px;
}

.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3rem;
}

.tab:hover {
  color: $primary;
}

.active-tab {
  color: $primary;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 4px solid $primary-light;
}

.inactive-tab {
  color: #b2d9ff;
  border-bottom: 1px solid $primary-light;
}

.soruKokuCombobox {
  color: #ebedf3;
}

.kazanimLayout {
  position: absolute;
  width: 40%;
  right: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  opacity: 0;
  padding: 10px;
  transform: translateX(100%);
  .kazanimList {
    height: 120px;
    max-height: 120px;
    overflow: auto;
  }
  .form-group {
    margin-bottom: 10px;
    label {
      margin-bottom: 2px;
    }
  }
}
.open {
  animation: openKazanimLayout 0.5s forwards;
}

.close {
  animation: closeKazanimLayout 0.5s forwards;
}

@keyframes openKazanimLayout {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes closeKazanimLayout {
  from {
    opacity: 1;
    transform: translateX(0%);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}

.titleAnim {
  animation: titleHighlightAnim 1.5s forwards;
}

@keyframes titleHighlightAnim {
  0% {
    transform: scale(1);
    color: black;
  }
  50% {
    transform: scale(1.05);
    color: #dde9ff;
  }
  100% {
    transform: scale(1);
    color: black;
  }
}

.questionList {
  height: 41%;
}

.questionView {
  height: 45%;
}

.stepLayout {
  border-radius: 50px;
}

.stepCircle {
  width: 30px;
  height: 30px;
  background-color: #f6f7f9;
}
.kayitFormContainer {
  height: 86%;
}
