.lms-filter-form {
  width: 20rem;
}

// Drawer için sayfa içindeymiş görünümü verdim
@media screen and (min-width: 992px) {
  .aside-minimize-hover,
  .aside-minimize-hoverable {
    .MuiDrawer-paperAnchorTop,
    .MuiDrawer-paperAnchorBottom {
      left: 265px;
    }
  }

  .aside-minimize {
    .MuiDrawer-paperAnchorTop,
    .MuiDrawer-paperAnchorBottom {
      left: 70px;
    }
  }
}
