.userNotes-content {
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul {
    width: 500px;
    display: flex;
    flex-direction: column;
    // background: white;
    padding: 20px;
    border-radius: 25px;
  }

  li {
    background-color: rgba(214, 214, 214, 0.5);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    cursor: pointer;
  }

  li:last-child {
    margin-bottom: 0px;
  }

  .row {
    width: 100%;
    height: 8px;
    background-color: #999;
    border-radius: 10px;
    margin-top: 12px;
  }
}
