// .lms-menu-open {
//   background-color: red;
// }

// .lms-menu-close {
//   background-color: orange;
// }

.icerik-item {
  border: 1px solid lightgray;
  width: 100% !important;
  background-color: rgba(228, 230, 239, 0.08) !important;
  .btn {
    background-color: rgba(228, 230, 239, 0.08) !important;
  }
}

.lms-homework-menu-open {
  width: 240px;
  .nav-item {
    // .active {
    //   background-color: transparent !important;
    // }
    .active.text-content {
      background-color: rgba(137, 80, 252, 0.08) !important;
      color: #8950fc !important;
      .btn {
        background-color: #eee5ff !important;
        color: #8950fc !important;
      }
    }
    .active.book-content {
      background-color: rgba(137, 80, 252, 0.08) !important;
      color: #8950fc !important;
      .btn {
        background-color: #eee5ff !important;
        color: #8950fc !important;
      }
    }
    .active.question-content {
      background-color: rgba(54, 153, 255, 0.08) !important;
      color: #3699ff !important;
      .btn {
        background-color: #e1f0ff !important;
        color: #3699ff !important;
      }
    }
    .active.video-content {
      background-color: rgba(255, 168, 0, 0.08) !important;
      color: #ffa800 !important;
      .btn {
        background: #fff4de;
        color: #ffa800 !important;
      }
    }
    .active.document-content {
      background-color: rgba(27, 197, 189, 0.08) !important;
      color: #1bc5bd !important;
      .btn {
        background-color: #c9f7f5 !important;
        color: #1bc5bd !important;
      }
    }
    .active.game-content {
      background-color: rgba(197, 27, 189, 0.08) !important;
      color: #c51ba9 !important;
      .btn {
        background-color: #f7c9f7 !important;
        color: #c51ba9 !important;
      }
    }
    .active.test-content {
      background-color: rgba(197, 27, 189, 0.08) !important;
      color: #c51ba9 !important;
      .btn {
        background-color: #f7c9f7 !important;
        color: #c51ba9 !important;
      }
    }
  }
}

.course-container {
  display: flex;
  touch-action: none;
  margin: 1rem auto;
}

.dropzone {
  flex: 1;
  width: 100%;
  height: 500px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  overflow-x: scroll;
  overflow-x: hidden;
  .undefined {
    // background: orange !important;
  }
}

.grid-item {
  padding: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-item-content {
  width: 100%;
  height: 100%;
  background: #08e;
  position: relative;
}

.grid-item-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  user-select: none;
}
.grid-item-content:hover .grid-item-btn {
  display: block;
}
.grid-item-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 100%;
  padding: 5px;
  display: none;
  border: none;
  color: white;
  background: rgba(255, 0, 0, 0.445);
}
.page-number {
  position: absolute;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: red;
  z-index: 2;
  bottom: 0px;
  left: calc(100% - 50%);
}

.page-flip {
  background: white;
  border-right: 0;
  box-shadow: inset 0px 0px 1px 3px rgba(102, 102, 102, 0.226);
}
.page-flip-img {
  margin: 3%;
  // position: absolute !important;
  width: 94%;
  height: 94%;
  object-fit: fill !important;
}
.flip-img-shadow {
  display: inline-block;
  position: relative;

  -moz-box-shadow: rgba(0, 0, 0, 0.8) 3px 3px 10px inset;
  -webkit-box-shadow: rgba(0, 0, 0, 0.8) 3px 3px 10px inset;
  box-shadow: rgba(0, 0, 0, 0.8) 3px 3px 10px inset;
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
}
.page-content-flip {
  background: blue;
}

.modal-90w {
  width: 80% !important;
  height: 80% !important;
  max-width: none !important;
}

.page-flip {
  img {
    object-fit: contain !important;
  }
}

.custom-drawer {
  .grup-detay-container {
    padding: 16px !important;
    height: 100% !important;
    .tab-content {
      height: 90% !important;
      .tab-pane {
        height: 100% !important;
        #staff-list {
          height: 100% !important;
        }
        #student-list {
          min-height: 100% !important;
        }
      }
    }
  }
}
.custom-drawer-inner {
  #course-list {
    height: 100%;
  }
}
