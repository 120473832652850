.react-datepicker__header {
  background-color: white !important;
  border: none !important;
}

.react-datepicker {
  border: none !important;
  //box-shadow: 0px 1px 29px 8px rgba(0, 0, 0, 0.2) !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  background-color: #dde9ff !important;
  color: #0355f9 !important;
}

.react-datepicker__navigation--next,
.react-datepicker__navigation--previous {
  margin: 5px !important;
}
.react-datepicker__month-select,
.react-datepicker__year-select {
  //   color: lawngreen !important;
  padding: 4px !important;
  border: none !important;
  background-color: #ebecf0 !important;
}

.react-datepicker__triangle,
.react-datepicker__triangle::before {
  background-color: transparent !important;
  border: transparent !important;
}


.react-datepicker__month-dropdown-container{
  margin-right: 20px !important;
}