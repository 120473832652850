body {
  background: #eef0f7 !important;
  overflow-x: hidden !important;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background-color: #cdc6c6;
  border-radius: 100px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 100px;
  //background-image: linear-gradient(180deg, #d0368a 0%, #708ad4 99%);
  background-image: linear-gradient(180deg, #d59732 0%, #e25088 30%, #af36b6 60%, #4385f0 99%);
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}

.lms-scroll-bar {
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-gutter: stable !important;
}

.lms-scroll-bar::-webkit-scrollbar {
  width: 10px;
}

.lms-scroll-bar::-webkit-scrollbar-track {
  background-color: #cdc6c6;
  border-radius: 100px;
}

.lms-scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 100px;
  //background-image: linear-gradient(180deg, #d0368a 0%, #708ad4 99%);
  background-image: linear-gradient(180deg, #d59732 0%, #e25088 30%, #af36b6 60%, #4385f0 99%);
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}

.lms-kazanim-panel {
  width: 100%;
  border: 1px solid;
  border-radius: $border-radius;
  padding: 1rem;
  margin: 0.5rem;
  .lms-kazanim-yuzde {
    font-size: 1.8rem;
    font-weight: bold;
  }
}

.lms-kazanim-box {
  border-radius: $border-radius;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  min-width: 75px;
}

.lms-kazanim-green {
  background-color: $green-tint;
  color: $green;
  border-color: $green-tint;
}
.lms-kazanim-blue {
  background-color: $blue-tint;
  color: $blue;
  border-color: $blue-tint;
}
.lms-kazanim-red {
  background-color: $red-tint;
  color: $red;
  border-color: $red-tint;
}
.lms-kazanim-tooltip {
  font-size: 1rem;
}

.MuiTooltip-tooltip {
  font-size: 1rem !important;
  background-color: $gray-200 !important;
  color: $gray-700 !important;
}

.lms-item-detail {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0.5rem 1rem;
  background-color: $gray-200;
  border-radius: $border-radius;
  label {
    font-weight: bold;
  }
  margin: 1rem;
}

.lms-filter-view {
  min-width: 550px;
}

.lms-label-grid {
  font-weight: bold;
  color: $gray-500;
  text-transform: uppercase;
  font-size: 1rem;
}

.lms-grid-image {
  border: 1px solid $gray-400;
  border-radius: $border-radius;
  padding: 0.5rem;

  img {
    max-width: 350px;

    .small-question-img & {
      max-height: 60px;
    }
  }
}

.table-align-top {
  vertical-align: top !important;
}

.table-hover tbody tr:hover {
  background-color: rgba($blue-tint, 0.4) !important;
}

.lms-wizard-line {
  width: 160px;
  height: 4px;
  display: block;
}

.lms-wizard-btn {
  cursor: pointer;
}

.lms-wizard-disabled {
  background-color: $gray-200 !important;
}

.lms-wizard-text-disabled {
  color: $gray-200 !important;
}

.lms-group-input {
  div {
    width: 100%;
  }
}

.lms-group-btn {
  height: 38px;
  width: 38px;
  margin-top: 26px !important;
  padding: 0;
  min-width: 38px !important;
  margin-left: -6px;
  span {
    width: 38px;
    margin: 0;
  }
}

.lms-round-btn {
  width: 38px;
  height: 38px;
  border-radius: 64px;
  padding: 0 !important;
}

.lms-person-list-image {
  width: 64px !important;
  height: 64px !important;
  border-radius: 64px;
}

.lms-user-upload-image {
  border: 0px solid #dadada;
  border-radius: 64px;
  width: 40px !important;
  height: 40px !important;
  object-fit: cover !important;
}

.lms-user-image-md {
  border: 0px solid #dadada;
  border-radius: 64px;
  width: 50px !important;
  height: 50px !important;
  object-fit: cover !important;
}

.lms-detail-image {
  width: 200px;
  height: auto;
  object-fit: cover;
}

.lms-course-item-active {
  color: $primary !important;
}

.image img {
  max-width: 100% !important;
}

.lms-kazanim-box {
  border-radius: $border-radius;
  background-color: $blue-tint;
  color: $blue-dark;
  font-size: 11px;
  font-weight: bold;
  padding: 0.5rem 1rem;
  margin: 1rem;
}

.lms-item-detail {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0.5rem 1rem;
  background-color: $gray-200;
  border-radius: $border-radius;
  label {
    font-weight: bold;
  }
  margin: 1rem;
}

.lms-filter-view {
  min-width: 550px;
}

.lms-grid-image {
  border: 1px solid $gray-400;
  border-radius: $border-radius;

  img {
    max-width: 350px;
  }
}

.lms-image-type {
  width: 48px;
  height: 48px;
  display: block;
  border-radius: 4px;
}

.lms-image-type-alive {
  @extend .lms-image-type;
  background-color: $white;
  color: #4cd964;
}

.lms-image-type-time {
  @extend .lms-image-type;
  background-color: $white;
  color: #5a5a5a;
}

.lms-image-type-date {
  @extend .lms-image-type;
  background-color: $white;
  color: #5a5a5a;
  width: 48px;
  height: 48px;
}

.lms-class-button {
  width: 36x;
  height: 36px;
}

.lms-outside-logo {
  max-width: 100px;
}

.lms-student {
  width: 20%;
}

.lms-badge-tooltip,
.lms-pointer {
  cursor: pointer;
}

.lms-student-level-selected {
  border-left: 4px solid $primary !important;
}

.lms-take-note {
  display: block;
  height: 550px;
  min-width: 550px;
  position: fixed;
  bottom: 30px;
  z-index: 1000;
  right: 0;
}

.ck-editor__editable {
  height: 280px;
  max-height: 280px;
}

.lms-timetable-item-column-width {
  width: 18%;
}

.lms-answer-sheet-result-box {
  width: 120px;
  border-radius: $border-radius;
}

.lms-correct {
  background-color: $green !important;
  border-color: $green !important;
  color: $light !important;
}

.lms-blank {
  background-color: transparent;
}

.lms-wrong {
  background-color: $red !important;
  border-color: $red !important;
  color: $light !important;
}

.live-avatar {
  width: 150px !important;
  height: 150px !important;
}
.oldpage {
  font-family: "Comic-sans";
  background: #b8b8b8;
}
.top-label {
  color: red;
  font-size: 16px;
}

.cursor-move {
  cursor: move;
}
.bg-custom-gray {
  background-color: #fdfdfd !important;
  border-radius: 8px !important;
}

.lms-card-max-height {
  height: 100%;
}
.lms-share-view {
  min-height: 640px;
}
.lms-card-animated {
  transition-timing-function: ease-out;
  transition: 0.2s;
  transform: scale(1);
  cursor: pointer;
}

.lms-card-animated:hover {
  transition: all;
  transition-timing-function: ease-in;
  transition: 0.2s;
  transform: scale(1.2);
  z-index: 9000;
}

.lms-text-elipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lms-calisma-bar {
  border-bottom: 4px solid #1bc5bd;
  color: #1bc5bd;
}

.lms-stundent-filter-bar {
  position: absolute;
  text-align: right;
  right: 0px;
  bottom: 0px;
  width: 550px;
  height: auto;
}

.lms-lesson-date-item {
  width: 48px;
  min-width: 48px;
  height: 48px;
}

.lms-login-form {
  width: 200px;
}

.lms-login {
  display: flex;
  flex-direction: row;
}

.lms-login-mobile-logo {
  display: none;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.lms-game-content {
  height: 650px;
}

@media only screen and (max-width: 580px) {
  .lms-login {
    flex-direction: column;
  }
  .lms-login-left {
    display: none;
  }
  .lms-login-mobile-logo {
    display: block;
  }
  .lms-login-form-container {
    display: flex;
    flex-direction: column;
    img {
      margin-bottom: 16px;
      width: 123px;
      height: 140px;
    }
  }
}

@media only screen and (max-height: 520px) {
  .lms-login-mobile-logo {
    display: block;
  }
  .lms-login-form-container {
    display: flex;
    flex-direction: column;
    img {
      margin-bottom: 16px;
      width: 200px;
      height: 200px;
    }
  }
}

.lms-student-dashboard {
  .lms-student-dashboard-profile-img {
    max-width: 48px;
    max-height: 48px;
  }
  .lms-story-item {
    img {
      border-radius: 50%;
      border: 4px solid $light;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
      cursor: pointer;
    }
  }
  .lms-student-dashboard-connections-card {
    margin-top: 10px;
    padding: 6px;
    .lms-student-dashboard-connections-icon {
      margin-right: 10px;
      width: 48px;
      height: 48px;
    }
  }
  .lms-student-dashboard-daily-program-card {
    padding: 12px;
    .lms-student-dashboard-daily-program-card-header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-right: 4px;
      .lms-student-dashboard-daily-program-card-header-container-text {
        margin-top: 10px;
        padding: 12px;
      }
    }
    .lms-student-dashboard-calisma-onerileri-header {
      .lms-calisma-bar-img {
        position: absolute;
        z-index: 300;
        right: 44px;
        bottom: 4px;
        width: 240px;
        height: auto;
      }
    }
  }
  @media only screen and (min-width: 768px) {
    .lms-student-dashboard-outside-logo {
      width: 50%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .lms-mobile-bottom-navigation {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 8%;
    background-color: red;
  }
  .lms-scrollbar {
    .PrivateSwipeArea-root-1 {
      z-index: 0 !important;
    }
  }
}

.react-grid-item.react-grid-placeholder {
  background: $primary !important;
  opacity: 0.1 !important;
  border-radius: 8px;
}

.lms-border-bottom {
  border-bottom: 4px solid;
}

.lms-border-primary {
  border-color: $blue;
}

.lms-border-danger {
  border-color: $danger;
}

.lms-border-warning {
  border-color: $warning;
}

.lms-border-success {
  border-color: $success;
}

.lms-border-info {
  border-color: $info;
}

.lms-border-radius {
  border-radius: 15px !important;
}

.header {
  z-index: 301 !important;
}

.header-mobile {
  z-index: 302 !important;
}

.lms-login-btn {
  border: 0px solid transparent;
  background: transparent;
  width: 169px;
  height: 50px;
  img {
    width: 129px;
    height: 40px;
  }
}

.lms-image-earning {
  img {
    max-width: 200px;
  }
}

.lms-day-selector {
  z-index: 99;
  position: absolute !important;
  margin-top: -43px;
  margin-left: -182px;
}

.lms-date-select-list span {
  border-right: 1px solid $gray-200;
  padding: 8px;
}

.lms-date-select-list span:hover {
  background-color: $gray-200;
  cursor: pointer;
}

.lms-no-shadow {
  box-shadow: none !important;
}

.lms-rapor-menu {
  width: 330px;
}

.lms-slide-button {
  //background: $gray-400;
  color: $gray-800;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 4px;
  width: 220px;
  height: 35px;
  padding: 8px 10px;
  a {
    color: #3f4254;
  }
}

.lms-tab {
  cursor: pointer;
}

.lms-tab-first-item-active {
  color: $gray-400;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 8px;
  margin: 8px;
  background: url("../../../public/media/svg/tab-first-item-active.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 230px;
  margin-left: 0px;
  z-index: 100;
}

.lms-tab-first-item-passive {
  color: $gray-400;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 8px;
  margin: 8px;
  background: url("../../../public/media/svg/tab-first-item-passive.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 230px;
  margin-left: 0px;
  z-index: 0;
}

.lms-tab-items-active {
  color: $gray-400;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 8px;
  margin: 8px;
  background: url("../../../public/media/svg/tab-items-active.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 230px;
  margin-left: -20px;
  left: 0px;
  position: relative;
  z-index: 100;
}

.lms-tab-items-passive {
  color: $gray-400;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 8px;
  margin: 8px;
  background: url("../../../public/media/svg/tab-items-passive.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 230px;
  margin-left: -20px;
  left: 0px;
  position: relative;
}

.lms-tab-active {
  color: $primary;
}

.lms-custom-label-value {
  font-size: 1.4rem;
  font-weight: 700;
}

.lms-custom-label-subheader {
  color: $gray-700;
  font-size: 1.3rem;
  font-weight: 500;
}

.lms-custom-label-value-sm {
  font-size: 14px;
  font-weight: 600;
}

@media only screen and (max-width: 1980px) {
  .lms-custom-label-subheader {
    font-size: 1rem;
  }

  .lms-custom-label-value-sm {
    font-size: 1rem;
  }

  .lms-custom-label-value {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 1080px) {
  .lms-custom-label-subheader {
    font-size: 12px;
  }

  .lms-custom-label-value {
    font-size: 12px;
  }

  .lms-custom-label-value-sm {
    font-size: 12px;
  }
}

.lms-custom-label-header {
  color: #8f9bb3;
  font-size: 11px;
  font-weight: 400;
}

.lms-custom-label-info {
  font-size: 11px;
  font-weight: 700;
}

.lms-custom-label-border:last-child {
  border-right: 0px solid #dadada;
}

.lms-custom-info-icon {
  background-color: $primary;
  color: $white;
  padding: 12px;
  border-radius: 8px;
}

.carousel-indicators {
  top: 50px !important;
  padding-top: 30px !important;
}

.carousel-indicators li {
  background-color: $gray-500 !important;
  width: 8px !important;
  height: 8px !important;
  border-radius: 100%;
}
.lms-user-widget {
  background: $gray-200;
  border-radius: 64px;
  img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    margin: 1rem;
  }
}

.lms-grid-auto-row {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;
  grid-gap: 10px;
}

.lms-card-gradient {
  background: linear-gradient(89.96deg, #5397f7 0%, #0053c8 99.43%);
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.35);
  border-radius: 8px;
  border: 0px solid !important;
}

.lms-student-profile-avatar {
  border: 6px solid white !important;
  width: 72px !important;
  height: 72px !important;
  margin-top: -22px !important;
  box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.lms-image-upload-btn {
  width: 32px;
  height: 32px;
  background: $white;
  color: $gray-500;
  border: 2px solid #3a80e4;
  border-radius: 32px;
  margin-right: -8px;
  margin-right: -58px;
  position: absolute;
  margin-top: 21px;
}

.lms-table-header {
  background: #ffffff;
  border: 2px solid #e7e9ff;
  border-radius: 8px;
}

.lms-table-header-search-icon {
  position: absolute;
  margin-left: 15px;
  color: #8f9bb3;
}

.lms-table-header-responsive-search {
  display: none;
}

.lms-table-header-responsive-search-div {
  display: inherit;
}

.lms-table-header-resposive-search-bar {
  position: absolute;
  background: linear-gradient(89.96deg, #5397f7 0%, #0053c8 99.43%);
  right: 0;
  height: 56px;
  border-radius: 8px;
}

.lms-card-list-search-bar {
  border-radius: 8px;
  position: relative;
}

.lms-card-list-search-button {
  position: absolute;
  top: -8px;
  right: 2px;
  background: linear-gradient(89.96deg, #5397f7 0%, #0053c8 99.43%);
  border-radius: 12px;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  z-index: 99;
  display: flex;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.gradient-background {
  background: linear-gradient(89.96deg, #0053c8 0%, #5397f7 99.43%);
}

@media screen and (min-width: 990px) and (max-width: 1100px) {
  .lms-table-header-responsive-search {
    display: block;
  }

  .lms-table-header-responsive-search-div {
    display: none !important;
  }
}

.ag-column-drop-wrapper {
  margin: 0 !important;
  margin-bottom: 12px !important;
  .ag-column-drop {
    background-color: #f5f4ff !important;
    color: #968cdc;
    border-radius: 4px;
  }
  .ag-column-drop-list {
    background: transparent !important;
  }
}
.lms-follow-me-header {
  margin-top: 64px !important;
}

.lms-flow-user-icon {
  width: 56px;
  height: 56;
  border-radius: 64px;
}

.lms-user-mini-view {
  .lms-user-image-container {
    border: 0px solid #dadada;
    border-radius: 64px;
    width: 80px !important;
    height: 80px !important;
    object-fit: cover !important;
  }
}
