.ag-header-cell {
  font-weight: 600;
  color: #b5b5c3 !important;
  background-color: white;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-family: Poppins, "sans-serif";
}
.ag-theme-alpine .ag-root-wrapper {
  border: none !important;
}
.ag-theme-alpine .ag-cell {
  font-family: Poppins, Helvetica, "sans-serif";
  font-size: 13px !important;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

// .lgs-preference {
//   .ag-theme-alpine .ag-cell {
//     line-height: min(var(--ag-row-height, 40px), 20px) !important;
//   }
// }

.ag-theme-alpine .ag-tabs-header {
  display: none !important;
}
.ag-cell-focus,
.ag-cell-no-focus {
  border: none !important;
}
.no-border.ag-cell:focus {
  border: none !important;
  outline: none;
}
.ag-column-drop-wrapper {
  margin: 25px 0px;
  * {
    border: none !important;
    border-radius: 0.42rem !important;
  }
}
.ag-column-drop-list {
  background: rgba(54, 153, 255, 0.1);
}

.ag-cell-wrap-text {
  word-break: break-word !important;
}

// .ag-react-container {
//   // background: palevioletred;
//   height: 100%;
//   text-align: center;
//   vertical-align: middle;
// }

// .ag-cell.ag-cell-value {
//   background: teal;
// }

.ag-cell-label-container {
  .ag-icon-filter {
    color: red;
  }
}

.ag-paging-panel {
  border: none !important;
  background: #e1f0ff;
  border-radius: 8px;
  position: relative;
  padding: 8px;
  align-items: center;
  margin-top: 10px;
  display: none;
}

.ag-status-name-value {
  color: #3699ff;
  font-weight: 700;
  white-space: nowrap;
}

.ag-paging-panel-footer {
  margin-top: 16px;
  border: none !important;
  padding: 8px;
  align-items: center;
}

.ag-status-name-value-footer {
  color: #371d97;
  font-weight: 700;
  white-space: nowrap;
}

.ag-paging-panel-icons {
  align-items: center;
}

.ag-theme-alpine .ag-icon {
  font-size: 18px;
}

.ag-status-bar {
  position: absolute !important;
  bottom: 3px !important;
  z-index: 99;
  border: none !important;
}

.ag-side-bar-right {
  border: none !important;
}

.ag-header {
  border: none !important;
}

.ag-theme-alpine .ag-ltr .ag-row-group-leaf-indent {
  margin-left: 0px !important;
}

.lms-basic-data-grid {
  padding-bottom: 75px !important;
}

.ag-watermark {
  display: none !important;
}

.ag-text-field-input {
  color: black !important;
  font-weight: bold !important;
}

.attendance-container {
  .ag-theme-alpine
    .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-left {
    border-left-color: darkblue;
    border-width: 2px;
  }
  .ag-theme-alpine
    .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-right {
    border-right-color: darkblue;
    border-width: 2px;
  }
  .ag-theme-alpine
    .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-top {
    border-top-color: darkblue;
    border-width: 2px;
  }
  .ag-theme-alpine
    .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-bottom {
    border-bottom-color: darkblue;
    border-width: 2px;
  }
}

.custom-tooltip {
  border: 1px solid cornflowerblue;
  overflow: hidden;
}

.custom-tooltip p {
  margin: 5px;
}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}

.no-border {
  .lms-table-header {
    border: none !important;
  }
}
