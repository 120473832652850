.top-menu-layout {
  background-color: #f9f8f8;
  .top-menu-item {
    .item-text {
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      align-items: center;
      letter-spacing: -0.5px;
      color: #434343;
    }
  }
}
