.dashboard-menu-button {
  border: none;
  background: transparent;
}
.dashboard-menu-button:hover {
  background-color: #ebedee;
  border-radius: 8px;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: blue;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-grid-item:not(.react-grid-placeholder) {
  background: grey;
}

.window-header {
  background-color: lightblue;
  display: flex;
  justify-content: space-between;
  height: 20px;
}

.app-logo {
  display: flex;
}

.app-logo-icon {
  height: 20px;
}

.app-name {
  font-size: 14px;
  padding-left: 8px;
}

.actions-container {
  display: flex;
}

.window-button-style {
  background-color: transparent;
  border: none;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  transition: background-color 150ms ease;
}

.window-controls-icon {
  fill: #958fb2;
  pointer-events: none;
  user-select: none;
  width: 20px;
  height: 20px;
  transition: fill 150ms ease;
}

.window-controls-icon:hover {
  background-color: rgb(77, 46, 46);
}

.window-controls-icon {
  fill: #f4f2ff;
}

.settings-window:hover {
  background-color: orange;
}

.close-window:hover {
  background-color: #e6004c;
}

.close-window::focus {
  outline: none;
}

.dashboard-flow-button:hover {
  background: #eaeefe !important;
}

.lms-font-bold {
  font-weight: 700 !important;
}

.lms-title {
  color: #646e78;
  line-height: 36px;
  letter-spacing: 0.02rem;
}

.lms-shadow-sm {
  filter: drop-shadow(0px 12px 50px rgba(0, 0, 0, 0.04));
}

.lms-card-shape {
  height: 80%;
  width: 8px;
  position: absolute;
  border-radius: 100px;
  left: -2.5px;
  top: 0px;
  bottom: 0px;
  margin: auto 0px;
}

.message-photo {
  min-height: 100%;
}
.message-from-title {
  cursor: pointer;
  color: #1e2029;
  transition: all ease 0.2s;
}
.message-from-title:hover {
  color: rgb(117, 117, 117);
}

.day-button {
  cursor: pointer;
  flex: 1 1 auto !important;
  // padding: 0.5rem !important;
  flex-direction: column !important;
  display: flex !important;
  align-content: center !important;
  justify-content: center !important;
  width: 0px;
  // border-radius: 10px;
}

.active-day {
  // background: #4641f2 !important;
  // box-shadow: 0px 10px 20px 5px rgba(70, 65, 242, 0.1);
  color: #7738c7 !important;
  border-bottom: 2px solid #7738c7 !important;
}

.z-index-100 {
  z-index: 100 !important;
}
.d-content {
  display: contents !important;
}
