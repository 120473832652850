.lms-pill {
  width: 85px;
}

.lms-pill-zorluk-seviye-COK_KOLAY {
  color: $blue-dark;
  background-color: $blue-tint;
}

.lms-pill-zorluk-seviye-KOLAY {
  color: $green-dark;
  background-color: $green-tint;
}

.lms-pill-zorluk-seviye-ORTA {
  color: $yellow-dark;
  background-color: $yellow-tint;
}

.lms-pill-zorluk-seviye-ZOR {
  color: $orange-dark;
  background-color: $orange-tint;
}

.lms-pill-zorluk-seviye-COK_ZOR {
  color: $red-dark;
  background-color: $red-tint;
}

.lms-star-active {
  color: $yellow;
}

.lms-star-passive {
  color: $gray-500;
}

.badge-CAMPUS {
  color: #ffffff;
  background-color: #4ecff6;
}

.badge-CORPORATION {
  color: #ffffff;
  background-color: #f64e60;
}

.badge-SCHOOL {
  color: #ffffff;
  background-color: #b6f64e;
}

.add-button {
  border: dashed #2a8df8 1px;
  color: #2a8df8;
  background: white;
  width: 100%;
}
