.lms-new-edit-page-header {
  display: flex;
  margin: 2rem 0;

  h4 {
    color: $primary;
  }

  .lms-prev-next-btn-group {
    margin-left: auto;
  }
}

// download card
.lms-aws-file-download {
  border: 1px solid #ddd;
  border-radius: 0.42rem;
  display: flex;
  padding: 0.75rem 0;

  .lms-aws-file-download-btn {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .lms-aws-file-desc {
    display: flex;
    align-items: center;

    p {
      margin-bottom: 0;
    }
  }

  .lms-aws-file-details {
    font-size: 1.15rem;
    font-weight: 600;
    display: block;
    margin-bottom: 0.25rem;
  }

  .lms-aws-file-icon {
    background-color: $primary;
    color: $white;
    margin: -0.75rem 0;
    border-radius: 0.42rem 0 0 0.42rem;
    display: flex;
    padding: 0 1rem;
    align-items: center;
  }
}

.lms-operation-card {
  max-height: 350px;
  min-height: 350px;
  overflow-x: hidden;
}
.lms-outline-none {
  outline: none;
}

.lms-bg-transparent {
  background-color: transparent !important;
}

.lms-certain-student {
  .card {
    box-shadow: none !important;
  }
  .card-body {
    box-shadow: none !important;
  }
}

.lms-exam-earning-report-container {
  .card-custom {
    box-shadow: none !important;
  }
}

.lms-selected-teacher-label {
  background: "#EDEFF9";
  padding: 8px;
  border-radius: 64px;
  font-weight: 600px;
  font-size: 12px;
  color: "#5A32B0";
  width: 108px;
  text-align: center;
}

.lms-course-label {
  color: "#5A32B0";
  font-size: 16px;
  font-weight: 700;
}
.lms-card-modal-label {
  color: #5a32b0;
  font-size: 14px;
  font-weight: 700;
}
.lms-due-date-label {
  border-radius: 32px;
  height: 100px;
  box-shadow: 0px 8px 8px rgba(99, 61, 181, 0.5);
  background: #773cf6;
  text-align: center;
}

.lms-content-status-label {
  background-color: #f5f4ff;
  font-size: 9px;
  padding: 4px 8px;
  border-radius: 32;
  font-weight: 400;
  color: #5a32b0;
}

.lms-content-subtitle-label {
  font-size: 10px;
  font-weight: 700;
  color: #5a32b0;
  margin-top: -6px;
}

.lms-anim-card-modal {
  max-width: 1100;
  margin: 0px auto;
}

.lms-anim-card-modal-content {
  z-index: 20;
  position: relative;
  height: 200px;
  background-color: transparent;
}

.card-columns {
  column-count: 5;
}

@media (max-width: 1200px) {
  .card-columns {
    column-count: 4; 
  }
}

@media (max-width: 992px) {
  .card-columns {
    column-count: 3; 
  }
}

@media (max-width: 768px) {
  .card-columns {
    column-count: 2; 
  }
}

@media (max-width: 576px) {
  .card-columns {
    column-count: 1;
  }
}

.lms-checkbox-container {
  span {
    background: #cdcdcd !important;
  }
  .checkbox > input:checked ~ span {
    background-color: #3699ff !important;
  }
}

.lms-chart-box-shadow {
  box-shadow: 0px 100px 80px rgba(48, 55, 74, 0.1),
    0px 41.7776px 33.4221px rgba(48, 55, 74, 0.0503198),
    0px 22.3363px 17.869px rgba(48, 55, 74, 0.0417275),
    0px 12.5216px 10.0172px rgba(48, 55, 74, 0.035),
    0px 6.6501px 5.32008px rgba(48, 55, 74, 0.0282725),
    0px 2.76726px 2.21381px rgba(48, 55, 74, 0.0196802);
}

.not-selectable {
  user-select: none !important;
  -webkit-user-select: none !important;
  -webkit-user-drag: none;
}
// $gr-1: linear-gradient(170deg, #01e4f8 0%, #1d3ede 100%);
// $gr-2: linear-gradient(170deg, #b4ec51 0%, #429321 100%);
// $gr-3: linear-gradient(170deg, #c86dd7 0%, #ea4646 100%);

// .gr-1 {
//   background: $gr-1;
// }
// .gr-2 {
//   background: $gr-2;
// }
// .gr-3 {
//   background: $gr-3;
// }

// * {
//   transition: 0.5s;
// }

// .h-100 {
//   height: 100vh !important;
// }
// .align-middle {
//   position: relative;
//   top: 50%;
//   transform: translateY(-50%);
// }

// .register-column {
//   margin-top: 3rem;
//   padding-left: 3rem;
//   &:hover {
//     padding-left: 0;
//     .register-card .txt {
//       margin-left: 1rem;
//       h1,
//       p {
//         color: rgba(255, 255, 255, 1);
//         opacity: 1;
//       }
//     }
//     a {
//       color: rgba(255, 255, 255, 1);
//       &:after {
//         width: 10%;
//       }
//     }
//   }
// }
// .register-card {
//   min-height: 170px;
//   margin: 0;
//   padding: 1.7rem 1.2rem;
//   border: none;
//   border-radius: 0;
//   color: rgba(0, 0, 0, 1);
//   letter-spacing: 0.05rem;
//   font-family: "Oswald", sans-serif;
//   box-shadow: 0 0 21px rgba(0, 0, 0, 0.27);
//   .txt {
//     margin-left: -3rem;
//     z-index: 1;
//     h1 {
//       font-size: 1.5rem;
//       font-weight: 300;
//       text-transform: uppercase;
//     }
//     p {
//       font-size: 0.7rem;
//       font-family: "Open Sans", sans-serif;
//       letter-spacing: 0rem;
//       margin-top: 33px;
//       opacity: 0;
//       color: rgba(255, 255, 255, 1);
//     }
//   }
//   a {
//     z-index: 3;
//     font-size: 0.7rem;
//     color: rgba(0, 0, 0, 1);
//     margin-left: 1rem;
//     position: relative;
//     bottom: -0.5rem;
//     text-transform: uppercase;
//     &:after {
//       content: "";
//       display: inline-block;
//       height: 0.5em;
//       width: 0;
//       margin-right: -100%;
//       margin-left: 10px;
//       border-top: 1px solid rgba(255, 255, 255, 1);
//       transition: 0.5s;
//     }
//   }
//   .ico-card {
//     position: absolute;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     width: 100%;
//     height: 100%;
//     overflow: hidden;
//   }
//   i {
//     position: relative;
//     right: -50%;
//     top: 60%;
//     font-size: 12rem;
//     line-height: 0;
//     opacity: 0.2;
//     color: rgba(255, 255, 255, 1);
//     z-index: 0;
//   }
// }

.svg-hover-red:hover {
  filter: invert(13%) sepia(94%) saturate(7466%) hue-rotate(0deg) brightness(94%) contrast(115%);
}

.post-img-content {
  img {
    max-width: 100% !important;
  }
}

.post-content {
  img {
    display: none !important;
  }
  a {
    color: #3699ff !important;
    text-decoration: underline !important;
  }
}

.lms-border-bottom {
  border-bottom: 2px solid $lms-primary !important;
}

.lms-avatar {
  min-height: 80px !important;
  max-height: 80px !important;
  min-width: 80px !important;
  max-width: 80px !important;
}

.lms-card-view {
  position: fixed !important;
  top: 0px;
  bottom: 0px;
  margin: auto 0px;
  right: 60px;
  overflow: hidden;
  z-index: 99;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.25);
  height: 534px;
  width: 360px;
  border-radius: 18px;
  background-color: #f5f4ff;
}

.custom-checkbox {
  .checkbox > input:checked ~ span {
    background-color: #a289d6 !important;
  }
}

.show-eye {
  opacity: 0 !important;
  transition: all ease 0.2 !important;
}

.login-password:hover .show-eye {
  opacity: 1 !important;
  transition: all ease 0.2 !important;
}

.counts {
  h3 {
    font-weight: 800 !important;
  }
}
