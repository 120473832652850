.lms-employee-chooser {
  display: flex;
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
}

.lms-employee-chooser-user-details {
  display: flex;
  align-items: center;
}

.lms-employee-chooser-user-image {
  width: 36px;
  height: 36px;
  overflow: hidden;
  background-color: #f8f8f8;
  text-align: center;
  //background-image: url("../../../public/media/svg/icons/General/User.svg");
  background-image: none;
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: center center;
  display: inline-block;
  margin-right: 0.75rem;

  img {
    position: relative;
    width: auto;
    height: 100%;
  }
}

.lms-employee-chooser-user-name {
  margin-right: 1rem;
}

.lms-employee-chooser-btn {
  margin-left: auto;
}

.lms-employee-chooser-cancel-btn {
  background-color: transparent;
  border: none;
}

.dialog-bg-dark {
  background-color: rgba($color: #000000, $alpha: 0.15);
}
