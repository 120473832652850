.lms-table-actions {
  list-style-type: none;
  padding: 0.5rem;
  margin: 0;
  margin-left: 1.2rem;
  background-color: $gray-100;
  border-radius: 2rem;
  display: inline-block;

  li {
    display: inline-block;
  }

  .table-action-btn {
    border-radius: 2rem;
    background-color: transparent;
    border: none;
    color: #371d97;
    width: 32px;
    height: 32px;
    margin: 0 0.2rem;
    &:hover {
      background-color: $white;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
      color: $primary;
    }
    &:disabled {
      color: $gray-200;
      background-color: transparent;
      box-shadow: none;
    }
  }

  .table-action-btn-active {
    background-color: $white;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    color: #371d97;
  }
}

.lms-grid-search {
  background-color: #f5f4ff !important;
}

.lms-grid-header {
  color: #371d97;

  background: transparent !important;
}

.lms-grid-actions {
  background: #eef0f8;
  border-radius: 8px;
  list-style-type: none;
  margin: 8px;
  display: inline-block;
  box-sizing: border-box;
  padding: 4px 8px;
  width: fit-content;
  height: fit-content;
  li {
    display: inline-block;
  }

  .table-action-btn {
    border-radius: 2rem;
    border: 0px solid;
    color: #371d97;
    background: transparent;
    width: 32px;
    height: 32px;
    &:hover {
      background-color: #371d97;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
      color: $white;
    }
    &:disabled {
      color: #9f93c8;
      background-color: transparent;
      box-shadow: none;
    }
  }

  .table-action-btn-active {
    background-color: $white;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    color: #371d97;
  }
}

.lms-grid-actions:empty {
  display: none !important;
}

.lms-table-cell-image {
  max-width: 100px;

  img {
    width: 100%;
    height: auto;
  }
}

.lms-table-cell-user-avatar {
  .lms-table-cell-image {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #f8f8f8;
    text-align: center;
    border: 1px solid #ccc;
    background-image: none;
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: center center;
  }

  img {
    position: relative;
    width: auto;
    height: 100%;
  }
}

.lms-datatable-row-clickable {
  cursor: pointer;

  &:hover {
    > td {
      background-color: #f9f9f9;
    }
  }
}

.lms-datatable-row-activate {
  > td {
    background-color: #f3f6f9;
    color: #3699ff;
  }
}

.selection-cell-header {
  width: 2rem;
}

.atama {
  .table th,
  .table td {
    padding: 0px !important;
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important;
    border: 0px !important;
  }
  .filter-label {
    display: block !important;
  }
  .search-label {
    margin-bottom: 0px !important;
    width: 100% !important;
  }
}

.lms-tableaction-dropdown {
  &:hover {
    border-radius: 2rem !important;
  }
  button {
    padding: 0px;
    background-color: transparent !important;
    border-color: transparent !important;
    color: $gray-600 !important;
    margin: 0.1rem 0.6rem;
    width: 32px;
    height: 32px;
  }
  &:hover {
    background-color: $white;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    color: $primary !important;
  }
  &:disabled {
    color: $gray-200;
    background-color: transparent;
    box-shadow: none;
  }
  button:after {
    display: none;
  }
}

.lms-gridaction-dropdown {
  &:hover {
    border-radius: 2rem !important;
    background: #371d97 !important;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    color: $white;
  }
  button {
    padding: 0px;
    background-color: transparent !important;
    border-color: transparent !important;
    color: #371d97 !important;
    margin: 0.1rem 0.6rem;
    width: 32px;
    height: 32px;
    &:hover {
      background-color: $white;
      color: $white !important;
    }
    &:disabled {
      color: $gray-200;
      background-color: transparent;
      box-shadow: none;
    }
  }
}

// .btn.btn-primary:disabled.dropdown-toggle:after {
//   color: #004c98 !important;
// }

.lms-gridaction-dropdown:hover > button {
  background: none !important;
  color: $white !important;
}

// .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after {
//   color: $primary !important;
// }
