//
// Layout Config
//

// Root Font Sizes
$root-font-size: 13px; // Root font size for desktop mode
$root-font-size-lg: 13px; // Root font size for tablet mode
$root-font-size-md: 12px; // Root font size for mobile mode

// Page Background Color
$page-bg: #ffffff !default;

// Page Paddings
$page-padding: (
  desktop: 25px,
  // Padding for desktop mode
  tablet-and-mobile: 15px // Padding for tablet and mobile modes,
) !default;
