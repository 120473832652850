.lms-text-primary {
  color: #7738c7 !important;
}

.lms-text-green {
  color: #3bc738 !important;
}

.lms-text-muted {
  color: #6a6a6a !important;
}

.lms-bg-green {
  background: #3bc738 !important;
}

.lms-bg-primary {
  background: #7738c7 !important;
}

.lms-bg-muted {
  background: #6a6a6a !important;
}

.lms-message-navs {
  .display-text {
    color: #7d8099 !important;
    font-weight: 600;
  }
  .nav.nav-pills .nav-item {
    margin-right: 0px !important;
  }
  .navs-header {
    height: 60px !important;
  }
  min-height: 500px !important;
  .nav-link {
    color: #755cbb !important;
    font-weight: 600;
    background: transparent !important;
    display: flex;
    align-items: center;
    border-radius: 0px !important;
    padding-left: 4rem !important;
  }

  .active {
    color: #5e46a1 !important;
    background: #c197f7 !important;
    .text {
      color: #5e46a1 !important;
    }
  }

  .lms-table-header {
    .nav.nav-pills {
      margin: 5px !important;
    }
    .nav-item {
      margin: 5px !important;
    }
  }
}

.lms-message-datagrid {
  .ag-theme-alpine .ag-root-wrapper {
    background-color: red !important;
  }
  .ag-row-even {
    background-color: #eaeaff !important;
  }
  .ag-row-odd {
    background-color: #f3f3ff !important;
  }
  .ag-root .ag-header {
    display: none;
  }
  .ag-column-drop-wrapper {
    display: none;
  }
}
