.custom-navs {
  .nav-link {
    // margin-right: 15px;
    // padding-top: 15px;
    color: #7d8099 !important;
    font-weight: 600;
    background: rgba(245, 248, 250, 0.5);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .active {
    color: #3699ff !important;
    background: white !important;
    -webkit-box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 25px 5px rgb(126 130 153 / 15%);
    .text {
      color: black !important;
    }
  }
}

.lms-left-navs {
  .display-text {
    color: #7d8099 !important;
    font-weight: 600;
  }
  .navs-header {
    height: 60px !important;
  }
  min-height: 500px !important;
  .nav-link {
    color: #7d8099 !important;
    font-weight: 600;
    background: white;
    display: flex;
    align-items: center;
  }

  .active {
    color: #3699ff !important;
    background: #eef0f7 !important;
    // -webkit-box-shadow: 0px 0px 25px 5px rgba(0, 0, 0, 0.15);
    // box-shadow: 0px 0px 25px 5px rgb(126 130 153 / 15%);
    .text {
      color: black !important;
    }
  }

  .lms-table-header {
    .nav.nav-pills {
      margin: 5px !important;
    }
    .nav-item {
      margin: 5px !important;
    }
  }
}

.lms-student-note-navs {
  .display-text {
    color: #7d8099 !important;
    font-weight: 600;
  }
  .navs-header {
    height: 60px !important;
  }
  .nav-link {
    color: #7d8099 !important;
    font-weight: 600;
    background: white;
    display: flex;
    align-items: center;
  }

  .active {
    color: #3699ff !important;
    background: #eef0f7 !important;
    .text {
      color: black !important;
    }
  }

  .lms-table-header {
    .nav.nav-pills {
      margin: 5px !important;
    }
    .nav-item {
      margin: 5px !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .lms-left-navs {
    width: 100% !important;
    min-height: auto !important;
    margin-bottom: 1rem;
    margin-right: 0rem !important;
  }
  .help-menu {
    display: none !important;
  }
}

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */
.container {
  padding: 2px 16px;
}
