// arka arkaya modal body eklendiyse
.modal-body + .modal-body {
  border-top: 1px solid #ebedf3;
}

.lms-modal-personel-list {
  .card {
    background: transparent !important;
    box-shadow: none !important;
  }
  .card-body {
    padding: 0 !important;
  }
}
.lms-delete-modal {
  .card {
    background: transparent !important;
    box-shadow: none !important;
  }
}

.ani {
  animation: neon 0.5s infinite;
}

.modal-scrool::-webkit-scrollbar {
  width: 10px;
}
.modal-scrool::-webkit-scrollbar-track {
  background-color: #ccc;
  border-radius: 10px;
}
.modal-scrool::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
}

.modal-scrool::-webkit-scrollbar-thumb:hover {
  background: #666;
}

.full-width-modal {
  width: 90vw; /* Occupy the 90% of the screen width */
  max-width: 90vw;
}
