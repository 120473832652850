.layout-top-bar {
  background: "#F4F4FF";
  height: 62px;
}

.layout-top-bar-button {
  height: 26px;
  background: #eef0f8;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25), inset 0px -2px 4px rgba(138, 102, 214, 0.36);
  border-radius: 82px;
  color: #5a32b0;
  border: 0px solid transparent;
  font-size: 11px;
  font-weight: 600;
}

.layout-admin-bottom-menu {
  position: absolute;
  border-radius: 8px;
  width: 60%;
  height: 60px;
  display: block;
  margin: 0 auto;
  bottom: 20px;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, #fafafa 0%, #eceff1 100%);
  box-shadow: inset 0px 0px 4px rgba(39, 97, 210, 0.25);
  filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.25));
}

.layout-sub-item {
  background: linear-gradient(180deg, #fafafa 0%, #eceff1 100%);
  box-shadow: inset 0px -1px 4px #558fc8;
  border-radius: 48px;
}

.layout-card-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
  pointer-events: none;
}

.layout-card-content-container.open {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1;
  overflow: hidden;
  padding: 40px 0;
}

.layout-card-content {
  pointer-events: auto;
  position: relative;
  border-radius: 20px;
  background: $white;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.layout-open .layout-card-content {
  height: auto;
  max-width: 700px;
  overflow: hidden;
  pointer-events: none;
}

.layout-card-open-link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.layout-card-image-container {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 420px;
  width: 100vw;
}

.layout-open .layout-card-image-container,
.layout-open .layout-title-container {
  z-index: 1;
}

.layout-title-container {
  position: absolute;
  top: 15px;
  left: 15px;
  max-width: 300px;
}

.layout-open .layout-title-container {
  top: 30px;
  left: 30px;
}

.layout-card-overlay {
  z-index: 1;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  will-change: opacity;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.layout-card-overlay a {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  left: 50%;

  transform: translateX(-50%);
}

.layout-border-radius {
  border-radius: 8px !important;
}

.layout-admin-mobile-button button {
  border: 1px solid transparent;
  color: #a9a9a9;
  font-size: 14px;
  background: transparent;
  width: 100%;
  text-align: left;
}

.layout-admin-mobile-button button:hover {
  color: #5a32b0;
}

.fc-scroller {
  scrollbar-gutter: stable !important;
}

.scroll {
  overflow-y: scroll !important;
  scrollbar-gutter: stable !important;
}

.scroll:active::-webkit-scrollbar-thumb,
.scroll:focus::-webkit-scrollbar-thumb,
.scroll:hover::-webkit-scrollbar-thumb {
  visibility: visible !important;
}
.scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  visibility: hidden !important;
}

.scroll::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
}

.text-blue {
  color: #067ed3 !important;
}
.text-green {
  color: #3cbc39 !important;
}
.text-red {
  color: #ff6969 !important;
}
.text-dark {
  color: #868686 !important;
}
.text-orange {
  color: #ffa800 !important;
}

.self-id-container {
  .tab-content {
    background: transparent !important;
  }
}
