.lms-text-primary {
  color: $lms-primary !important;
}

.lms-bg-primary {
  background: $lms-primary !important;
}

.lms-bg-light-primary {
  background: $lms-light-primary !important;
}

.lms-text-dark {
  color: $lms-dark !important;
}

.lms-bg-dark {
  background: $lms-dark !important;
}

.lms-text-muted {
  color: $lms-muted !important;
}

.lms-bg-muted {
  background: $lms-muted !important;
}
