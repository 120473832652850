.MuiContainer-root {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.modal {
  z-index: 1302 !important;
}
.modal-backdrop {
  z-index: 1301 !important;
}

.react-toast-notifications__container {
  z-index: 1500 !important;
}

.lms-datatable-row-expand-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
