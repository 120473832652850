.content-title {
  font-weight: bold;
}
.content-desc {
  color: gray;
}

.flex-gap {
  justify-content: flex-wrap;
  max-width: 1394px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.custom-card {
  // overflow: hidden;
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgb(0 0 0 / 8%) !important;
  position: relative;
  // cursor: pointer;
  // padding: 12px;
  // height: 450px;
  width: 214px;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 12px;
  img {
    object-fit: contain !important;
    height: 256px !important;
    width: 100% !important;
  }
  transition: all 0.2s;
  font-size: 12px !important;
}
.img {
  object-fit: contain;
  background-color: white;
  width: 100%;
}

.hidden {
  display: none;
}

.view-active {
  background-color: #ffffff;
  box-shadow: 0px 0px 8px 0px rgb(0 0 0 / 25%);
  color: #0355f9;
}

.icon-container {
  background-color: #f6f8fd;
  padding: 8px;
  border-radius: 50%;
  margin-right: 16px;
  color: #491fff;
  font-size: 8px !important;
}

.custom-card-button {
  opacity: 0;
  position: absolute;
  z-index: 99;
  right: 0px;
  bottom: 0px;
  border: none;
  font-weight: bold;
  color: #3699ff;
  background: transparent;
  transition: all 0.2s;
  background: #f6f8fd;
  padding: 4px;
  border-radius: 4px;
}
.custom-card-button-share {
  opacity: 0;
  position: absolute;
  z-index: 99;
  right: 0px;
  top: 0px;
  border: none;
  font-weight: bold;
  color: #3699ff;
  background: transparent;
  transition: all 0.2s;
  background: #f6f8fd;
  padding: 4px;
  border-radius: 4px;
}

.custom-card-button-remove {
  opacity: 0;
  position: absolute;
  z-index: 99;
  left: 0px;
  top: 0px;
  border: none;
  font-weight: bold;
  color: red;
  background: transparent;
  transition: all 0.2s;
  background: #f6f8fd;
  padding: 4px;
  border-radius: 4px;
}

.custom-card:hover .custom-card-button {
  opacity: 1;
  bottom: 10px;
  right: 10px;
}
.custom-card:hover .custom-card-button-share {
  opacity: 1;
  bottom: 10px;
  right: 50px;
}

.custom-card:hover .custom-card-button-remove {
  opacity: 1;
  top: 10px;
  left: 10px;
}

.custom-card:hover {
  // #3c16e4
  // opacity: 1;
  // bottom: 10px;
  // right: 10px;
  box-shadow: 0 0.01rem 0.05rem 0.05rem #3699ff !important;
}

.card-active {
  box-shadow: 0 0rem 0.8rem 0.08rem #369bffd2 !important;
}

.card-active-btn {
  opacity: 1 !important;
  right: 10px;
  bottom: 10px;
}

.hidden {
  display: none;
}
.hidden-field {
  display: none !important;
}

.custom-card-ribon {
  position: absolute;
  top: -6.1px;
  right: 10px;
  z-index: 2;
  -webkit-box-shadow: 0px 0px 23px -12px #000000;
  box-shadow: 0px 0px 23px -12px #000000;
}

.custom-card-ribon span {
  position: relative;
  display: block;
  text-align: center;
  color: white;
  background: #f8463f;
  font-size: 11px;
  line-height: 1;
  padding: 10px 6px 8px;
  border-top-right-radius: 8px;
  width: 70px;
}
.custom-card-ribon span:before,
.custom-card-ribon span:after {
  position: absolute;
  content: "";
}
.custom-card-ribon span:before {
  height: 6px;
  width: 6px;
  left: -6px;
  top: 0;
  background: #f8463f;
}
.custom-card-ribon span:after {
  height: 6px;
  width: 8px;
  left: -8px;
  top: 0;
  border-radius: 8px 8px 0 0;
  background: #c02031;
}

.dropdown-menu {
  transform: translate(0px, 44px) !important;
}
.custom-card-ribon.on-the-air {
  span {
    background: #c9f7f5 !important;
    color: #1bc5bd !important;
  }
  span:before {
    background: #c9f7f5 !important;
  }
  span:after {
    background: #1bc5bd !important;
  }
}
.custom-card-ribon.take-down {
  span {
    background: #ffe2e5 !important;
    color: #f64e60 !important;
  }
  span:before {
    background: #ffe2e5 !important;
  }
  span:after {
    background: #f64e60 !important;
  }
}

.custom-card-ribon.plan {
  span {
    background: #eee5ff !important;
    color: #8950fc !important;
  }
  span:before {
    background: #eee5ff !important;
  }
  span:after {
    background: #8950fc !important;
  }
}

.student-weekly-schedule {
  max-height: 450px;
}

.custom-drawer {
  min-height: calc(100vh - 65px) !important;
  max-height: calc(100vh - 65px) !important;
  height: calc(100vh - 65px) !important;
  overflow-x: hidden;
}
.custom-drawer-inner {
  min-height: calc(100vh - 65px - 58.5px - 2rem) !important;
  max-height: calc(100vh - 65px - 58.5px - 2rem) !important;
  height: calc(100vh - 65px - 58.5px - 2rem) !important;
  overflow-x: hidden;
}

.custom-drawer-inner-full-screen {
  min-height: calc(100vh - 65px) !important;
  max-height: calc(100vh - 65px) !important;
  height: calc(100vh - 65px) !important;
  overflow: hidden;
}

.course-drawer {
  min-height: calc(100vh - 65px - 58.5px - 2rem - 70px) !important;
  max-height: calc(100vh - 65px - 58.5px - 2rem - 70px) !important;
  height: calc(100vh - 65px - 58.5px - 2rem - 70px) !important;
  overflow-x: hidden;
}

@media only screen and (max-width: 768px) {
  .custom-drawer-inner-full-screen {
    overflow: visible;
  }
}

// .custom-navs.closeable:hover {
//   background: red;
// }
