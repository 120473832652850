.lms-green-gradient {
  background: linear-gradient(316.53deg, #a3f7cf 7.31%, #e8fae1 101.15%);
  box-shadow: 0px 16px 16px rgba(112, 255, 181, 0.34);
}

.lms-blue-gradient {
  background: linear-gradient(316.53deg, #a3b9f7 7.31%, #e1e5fa 101.15%);
  box-shadow: 0px 16px 16px rgba(185, 156, 245, 0.51);
}

.lms-orange-gradient {
  background: linear-gradient(180deg, #f9a322 0%, #fbc02b 100%);
  box-shadow: 0px 16px 16px rgba(239, 149, 14, 0.25);
}

.lms-br-gradient {
  background: linear-gradient(
    180deg,
    rgb(119, 60, 246) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgb(119, 60, 246) 100%
  );
}
.lms-vr-gradient {
  background: linear-gradient(180deg, #ffb380, rgba(255, 255, 255, 1) 50%, #ffb380);
}

.lms-at-green {
  background: linear-gradient(316.53deg, #15ed86 7.31%, #cbfab9 101.15%);
  box-shadow: rgb(201 253 228) 0px 16px 16px;
}
.lms-at-red {
  background: linear-gradient(316.53deg, #e14413 7.31%, #fec0ff 101.15%);
  box-shadow: 0px 16px 16px rgba(230, 90, 45, 0.33);
}
.lms-at-purple {
  background: linear-gradient(316.53deg, #5397f7 7.31%, #ffccf4 101.15%);
  box-shadow: 0px 16px 22px #aacdff;
}
.lms-at-orange {
  background: linear-gradient(316.53deg, #e1b413 7.31%, #ffddb6 101.15%);
  box-shadow: 0px 16px 16px rgba(239, 185, 62, 0.32);
}

.lms-at-hour-label {
  background: #f5f0ff;
  height: 64px;
  width: 64px;
  border-radius: 100%;
  font-weight: 600;
  font-size: 19;
  color: rgb(116 116 116);
}

.lms-bg-primary {
  background-color: #769aff !important;
}
