.image-renderer-container {
  overflow: hidden;
  position: relative;
  max-width: 800px;
}
.image-renderer {
  width: 100% !important;
  height: 100% !important;
  opacity: 0;
  // object-fit: cover;
  object-fit: cover !important;

  &.thumb {
    opacity: 1;
    transition: opacity 0.2s ease-out;
    position: absolute;
    &.isLoaded {
      opacity: 0;
    }
  }

  &.isLoaded {
    transition: opacity 0.2s ease-out;
    opacity: 1;
  }
}

.lms-student-preview {
  flex-flow: wrap;
  .preview-photo {
    max-width: 180px;
    max-height: 210px;
    min-height: 180px;
    min-width: 210px;
    object-fit: contain;
  }
  .preview-delete-hover {
    width: 95%;
    height: 95%;
    background: rgba(255, 0, 0, 0.6);
    position: absolute;
    cursor: pointer;
  }
  .preview-container {
    max-width: 320px;
    max-height: 320px;
    min-width: 320px;
  }

  .delete-button {
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 100%;
    cursor: pointer;
  }
}
