.fc-event-main {
  b {
    font-size: 1.2rem;
  }
}

.fc-theme-standard,
.fc-scrollgrid {
  border-color: transparent !important;
}
// .fc td,
// .fc th {
//   border-style: none;
// }

.fc-timegrid-axis {
  .fc-timegrid-col-frame {
    border-style: none !important;
  }
}

.fc .fc-timegrid-slot-minor {
  border: none !important;
}

.fc-day-today {
  background-color: transparent !important;
}
// .fc-timegrid-col-frame {
//   margin-right: 3px;
//   margin-left: 3px;
//   border: 1px solid #efefef !important;
//   border-radius: 4px;
// }

.fc-scrollgrid-sync-inner {
  a {
    // günlerin yazıldığı a
    pointer-events: none;
    color: #a3a3a3;
    font-weight: 400;
  }
}

.fc-timegrid-slots {
  // saatin yazıldığı yer
  color: #a3a3a3;
  font-weight: 400;
}
.fc-timegrid-slots {
  // programa eklenen dersin blunduğu row'un height'si
  // table tr {
  //   height: 75px;
  // }

  // table td {
  //   border-top: 0px;
  // }

  // table td :first-child {
  //   border: none;
  // }
}

.event-datail {
  position: relative;
  height: 100%;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  .ec-pill {
    position: absolute;
    font-size: 1.3rem;
    right: 5px;
    bottom: 5px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    display: inline-block;
  }
}

.fc-v-event {
  background-color: transparent !important;
  border: transparent !important;
}

.teachers {
  background: #fdfdfd;
  border-radius: 8px;
}

.component-header {
  cursor: pointer;
}
.fc-scroller-hidden {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.fc-scroller-hidden::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.fc-scroller::-webkit-scrollbar {
  width: 5px;
}
.fc-scroller::-webkit-scrollbar-track {
  background-color: #ccc;
  border-radius: 10px;
}
.fc-scroller::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
}

.fc-scroller::-webkit-scrollbar-thumb:hover {
  background: #666;
}
.fc-h-event .fc-event-main {
  color: none !important;
}
