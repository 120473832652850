.card-custom {
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 10%) !important;
}

.lms-study-left-menu {
  width: 240px;
}

.lms-study-menu-open {
  width: 240px;
}

.lms-study-menu-close {
  width: auto;
}

.lms-study-card {
  border-bottom: 1px solid rgba(#85b0fa, 0.3) !important;
  background-color: none !important;
  cursor: pointer;
}

.lms-question-sheet {
  min-height: 350px;
}

.lms-question-selected {
  background-color: $green !important;
  color: $white !important;
  border: none !important;
}

.lms-question-border {
  border-radius: $border-radius;
}

.lms-active-question {
  border-bottom: 3px solid $primary !important;
}

.lms-student-filter-text {
  font-weight: bold;
  font-size: larger;
}
.lms-student-filter-bar {
  background-color: #d7f9ef;
}
.lms-student-filter-filters {
  flex: 2;
  align-self: center;
  border-right-width: 4px;
}
.lms-student-filter-button {
  background-color: #0bb783;
  color: #ffffff;
  border-radius: 6px;
  border: none;
  margin-right: 10%;
}
.lms-student-dayweek-button-selected {
  background-color: #357df2;
  color: #ffffff;
  border-radius: 4px;
  border: none;
}
.lms-student-dayweek-button-unselected {
  background-color: #f8f8f8;
  color: #494949;
  border-radius: 4px;
  border: none;
}

.lms-student-datefilter-week {
  color: #494949;
  font-size: medium;
  align-self: center;
  text-align: center;
  min-width: 100px;
}
.lms-student-datefilter-days {
  color: #494949;
  font-size: medium;
  margin-top: 6px;
  min-width: 100px;
  text-align: center;
}
.lms-student-datefilter-button {
  border: none;
  background: transparent;
  font-size: large;
  font-weight: bold;
}

.w-profile {
  max-width: 24% !important;
}
.lms-student-course-card {
  height: 120px;
}
.lms-student-video {
  max-width: 25%;
  max-height: 25%;
}

.lms-homework-card {
  max-width: 250px !important;
  min-width: 250px !important;
  min-height: 250px !important;
  margin-left: 2%;
}

.lms-content-question {
  min-height: 400px;
}
.lms-answer-item-box {
  width: 48px;
  height: 48px;
  margin: 2px;
}
.lms-daily-container {
  min-height: 280px;
}
.lms-content-container {
  min-height: 500px;
}
.lms-daily-card {
  min-height: 280px;
}
.lms-daily-study-card {
  min-height: 120px;
}
.lms-study-container {
  min-height: 200px;
}
.lms-courses-card {
  min-height: 180px;
}
.lms-card-animated {
  transition-timing-function: ease-out;
  transition: 0.2s;
  transform: scale(1);
}
.lms-card-animated:hover {
  transition: all;
  transition-timing-function: ease-in;
  transition: 0.2s;
  transform: scale(1.2);
  position: relative;
  cursor: pointer;
}
.live-lesson {
  transition-timing-function: ease-out;
  transition: 0.2s;
  transform: scale(1);
}
.live-lesson:hover {
  transition: all;
  transition-timing-function: ease-in;
  transition: 0.2s;
  transform: scale(1.03);
  z-index: 9000;
  position: relative;
  cursor: pointer;
}

.content-leftMenu {
  min-height: 300px;
}

.content-timeLine {
  transition-timing-function: ease-out;
  transition: 0.2s;
  transform: scale(1);
}
.content-timeLine:hover {
  transition: all;
  transition-timing-function: ease-in;
  transition: 0.2s;
  transform: scale(1.1);
  z-index: 9000;
  position: relative;
  cursor: pointer;
}
.lms-question-content {
  min-height: 500px;
  padding: 1%;
}

.lms-question-sheet-option {
  width: 24px;
  height: 24px;
  display: block;
  transition-timing-function: ease-out;
  transition: 0.2s;
  transform: scale(1);
  span {
    line-height: 22px;
  }
}
.lms-question-sheet-option:hover {
  transition: all;
  transition-timing-function: ease-in;
  transition: 0.2s;
  transform: scale(1.2);
  z-index: 9000;
  position: relative;
  cursor: pointer;
}
.lms-question-sheet-index {
  width: 32px;
  height: 32px;
  display: block;
  transition-timing-function: ease-out;
  transition: 0.2s;
  transform: scale(1);
  text-align: center;
  font-weight: bold;
}
.lms-question-sheet-index:hover {
  transition: all;
  transition-timing-function: ease-in;
  transition: 0.2s;
  transform: scale(1.4);
  z-index: 9000;
  position: relative;
  cursor: pointer;
}
.lms-question-drawer {
  min-height: 500px !important;
}

.lms-image-card {
  max-width: 100%;
}

.lms-study-timeline-fit {
  height: fit-content;
}

.lms-content-leftmenu-item {
  transition: all;
  display: block;
  transition-timing-function: ease-out;
  transition: 0.2s;
  transform: scale(1);
  :hover {
    transition-timing-function: ease-in;
    transition: 0.2s;
    transform: scale(1.2);
    z-index: 1;
    position: relative;
    cursor: pointer;
  }
}
.lms-live-icon {
  color: #ff0000;
  position: absolute;
  left: 0;
}

.lms-classroom-homework {
  display: block;
  background-image: url("../../../public/media/svg/shapes/wave.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}
.lms-scrollbar {
  overflow: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgb(153, 153, 153);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #363636;
  }
}

.lms-classrooms {
  transition-timing-function: ease-out;
  transition: 0.2s;
  transform: scale(1);
}
.lms-classrooms:hover {
  transition: all;
  transition-timing-function: ease-in;
  transition: 0.2s;
  transform: scale(1.2);
  position: relative;
  cursor: pointer;
}
.lms-classroom-classes {
  max-height: 70px;
  height: 70px;
  max-width: 120px;
  width: 120px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
  margin-right: 2%;
  margin-top: 2%;
  padding: 1%;
  h6 {
    color: #9495cf !important;
  }
  button {
    color: #9495cf !important;
    :hover {
      border-radius: 8px;
      background-color: #c3c4e7;
    }
  }
}

.lms-student-card {
  width: 150px !important;
  min-width: 150px !important;
  height: 50px !important;
  min-height: 50px !important;
  img {
    border-bottom-left-radius: 10%;
    border-top-left-radius: 10%;
    width: 50px !important;
    height: 50px !important;
  }
}

.lms-minimized-student-list {
  height: 100% !important;
  width: 15%;
  max-width: 150px !important;
}
.lms-maximized-student-list {
  height: 100% !important;
  width: 95% !important;
}
.lms-student-detailed-view-close {
  width: 1px !important;
}
.lms-student-detailed-view-open {
  width: 100% !important;
}
.lms-maximized-student-card {
  width: 180px;
  height: 60px;
  img {
    height: 50px !important;
    max-width: 80px !important;
  }
  :hover {
    cursor: pointer;
    transition: all;
    transition-timing-function: ease-in;
    transition: 0.5s;
  }
}
.lms-minimized-student-card {
  width: 100%;
  height: 30px;
  img {
    display: none;
  }
  span {
    font-size: x-small;
    font-weight: normal;
  }
}

.lms-course-detail-days {
  margin-right: 2px;
  text-align: left;
  width: 14%;
}

.lms-course-item {
  align-self: center;
  max-width: 90%;
  margin-top: 5%;
  :hover {
    cursor: pointer;
  }
  img {
    max-width: 100%;
  }
}

.lms-detailed-classroom-left {
  max-width: 200px !important;
  width: 15% !important;
  margin-right: 10px !important;
}

.lms-detailed-classroom-right {
  width: 100% !important;
  margin-left: 10px !important;
}

.lms-uploaded-documents-download-button {
  align-self: center;
  width: 25px !important;
  height: 15px !important;
  border-radius: 15% !important;
  background-color: #357df2;
  color: white;
  :hover {
    cursor: pointer;
  }
}

.lms-student-avatar {
  height: 128px;
  width: 128px;
}

.lms-table-label-font {
  font-size: 11px;
  padding-bottom: 2px;
  width: 50%;
  text-align: flex-start;
}

.lms-rozetler-span {
  font-size: 16;
  font-weight: 700;
}

.lms-rozet-container {
  margin-top: 10px;
  margin-bottom: 10px;
  img {
    width: 42px;
    height: 42px;
  }
}
.lms-student-view {
  height: 100%;
  display: flex;
  flex-direction: column;
  .lms-info-card {
    width: 25%;
    margin-right: 10px;
  }
  .lms-tab-panel-container {
    width: 100% !important;
    .tab-panel-button-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      .left-side {
        background-color: #ffffff;
      }
    }
    .tab-panel {
      display: flex;
      flex-direction: column;
      .table-card {
        border-radius: 10px;
        padding: 16px 10px 10px 16px;
      }
    }
  }
}

.lms-kontrol {
  width: fit-content;
  display: flex;
  border-top: 2px solid whitesmoke;
  padding-top: 21px;
  margin-top: 14px;
  .lms-kontrol-empty-icon {
    background-color: #ffffff;
    border: 2px solid rgba(242, 242, 242, 1);
    margin-top: -30px;
    width: 16px;
    height: 16px;
    line-height: -10px;
    border-radius: 50%;
    margin-left: 4px;
    margin-right: 4px;
  }
  .lms-kontrol-done-icon {
    color: rgba(79, 227, 102, 1);
    background-color: #ffffff;
    border: 2px solid rgba(79, 227, 102, 1);
    margin-top: -31px;
    width: 10px;
    height: 10px;
    line-height: -10px;
    border-radius: 50%;
    margin-left: 2px;
    margin-right: 2px;
  }
}

.lms-pie-labels {
  display: flex;
  justify-content: space-evenly;
  span {
    margin-left: 2px;
  }
  .dogru {
    div {
      display: inline-block;
      background-color: #bcf0e1;
      width: 10px;
      height: 10px;
    }
  }
  .yanlis {
    div {
      display: inline-block;
      background-color: #f3bbbc;
      width: 10px;
      height: 10px;
    }
  }
  .bos {
    div {
      display: inline-block;
      background-color: #8cb6fa;
      width: 10px;
      height: 10px;
    }
  }
}

.lms-siralama-table {
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 8px;
  align-content: center;
  align-items: center;
  .lms-siralama-avatar {
    align-self: flex-start;
    width: 32px;
    height: 32px;
  }
  .lms-siralama-labels {
    font-size: 12px;
  }
}

.lms-icon-button {
  align-self: center;
  color: rgb(54, 153, 255);
  :hover {
    cursor: pointer;
    background-color: rgb(225, 240, 255);
  }
}

.lms-detailed-work-image {
  max-width: 150px !important;
  max-height: 150px !important;
}

.lms-myassignedgroup-card {
  min-width: 290px;
  max-width: 290px;
  transition: all 0.2s ease-in-out;
}

.lms-myassignedgroup-card:hover {
  transform: scale(1.2);
}

.h-w-12 {
  min-width: 12px !important;
  max-width: 12px !important;
  min-height: 12px !important;
  max-height: 12px !important;
}

.h-w-16 {
  min-width: 16px !important;
  max-width: 16px !important;
  min-height: 16px !important;
  max-height: 16px !important;
}

.h-w-24 {
  min-width: 24px !important;
  max-width: 24px !important;
  min-height: 24px !important;
  max-height: 24px !important;
}

.h-w-40 {
  min-width: 40px !important;
  max-width: 40px !important;
  min-height: 40px !important;
  max-height: 40px !important;
}
