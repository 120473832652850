.image_picker {
  width: 100%;
}
.image_picker .responsive {
  position: relative;
  margin: 0 6px;
  float: left;
  opacity: 1;
}
.image_picker .responsive .thumbnail {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 150px;
  opacity: 1;
  margin: 0;
}
.image_picker .responsive .thumbnail:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.7);
}
.image_picker .responsive .selected {
  background-color: #008cba;
}
.image_picker .responsive:hover {
  cursor: pointer;
}
.image_picker .responsive .checked {
  display: none;
}
.image_picker .selected {
  opacity: 0.7;
}
.image_picker .selected .checked {
  display: inline-block;
  transition: 0.5s ease;
  position: absolute;
  bottom: 0;
  right: 0;
}
.image_picker .selected .checked .icon {
  background-size: cover;
  height: 75px;
  width: 75px;
}
.image_picker .clear {
  clear: both;
}
