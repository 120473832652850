.loading {
  z-index: 10000;
  background-color: rgb(255 255 255 / 80%);
  color: $white;
  width: 100%;
  height: 100%;
}

.backdrop {
  pointer-events: none;
}

.registration-loading {
  cursor: progress !important;
  position: absolute !important;
  z-index: 6 !important;
  opacity: 0.2;
}
