.lms-comment-yorum {
  .lms-user-upload-image {
    width: 42px !important;
    height: 42px !important;
  }
}
.custom-pill {
  border-radius: 2px;
  font-size: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  display: inline-block;
  color: #3699ff;
  background-color: #e1f0ff;
  margin-right: 5px;
  span {
    padding: 5px !important;
  }
  .c-pill-btn {
    display: inline-block;
    right: 0px;
    padding: 3px 6px;
    height: 100%;
    font-weight: bold;
    &:hover {
      background-color: #ffbdad;
      color: #de350b;
    }
  }
}
.make-post {
  .ck {
    .ck-toolbar.ck-toolbar_grouping {
      border: none !important;
    }
    .ck-editor__main {
      margin-bottom: 25px !important;
    }
  }
  .ck.ck-editor {
    display: flex;
    flex-direction: column-reverse;
  }
}
.post {
  img {
    max-width: 100%;
  }
}

.user-posts-img {
  max-width: 120px !important;
  img {
    min-height: 120px !important;
  }
}
