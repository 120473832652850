.MuiTreeItem-iconContainer {
    width: auto !important;
    margin-right: 0px !important;
  }
  
  .MuiTreeItem-label {
    padding-left: 0px !important;
  }
  
  .MuiTreeItem-label:hover {
    background-color: transparent !important;
  }
  
 
  .MuiTreeItem-group {
    border-left: 1px dashed lightgray;
  }
  
  .MuiTreeItem-group {
    margin-left: 13px !important;
  }