.lms-message-send {
  .ck .ck-sticky-panel__content {
    margin-bottom: 10px !important;
    background: #ffffff !important;
    border: 2px solid #e7e9ff !important;
    border-radius: 8px !important;
    padding: 2px !important;
  }
  .ck.ck-toolbar {
    border: none !important;
  }
}

.clear-ck .p1 {
  padding: 0px !important;
  margin: 0px !important;
}
