.livecourse-card-carousel {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 200px;
  align-items: left;
  position: relative;
}

.livecourse-card {
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #eeeeee;
  padding: 30px;
  box-shadow: 0 10px 5px rgba(0, 0, 0, 0.1);
  width: 420px;
  height: 200px;
  transition: all 0.75s ease;
  opacity: 0;
  position: absolute;
  transform: scale(0.85) translateY(50px);
}

.livecourse-card h2,
.livecourse-card p {
  line-height: 1.5;
  margin: 0;
}

.livecourse-card.active {
  opacity: 1;
  transform: scale(1) translateY(0);
  box-shadow: 0 30px 20px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.livecourse-card.next {
  opacity: 1;
  z-index: 0;
}

.livecourse-card.prev {
  transform: scale(1.1) translateY(-50px);
  z-index: 2;
  opacity: 0;
  visibility: hidden;
}
