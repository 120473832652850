.instant-message {
  .right-border {
    border-right: 1px solid #bcbcbc;
  }

  .bottom-border {
    border-bottom: 0.5px solid #dcdcdc;
  }
  .message-list-header {
    max-height: 60px;
    min-height: 60px;
    background-color: #f4f4f4;
    .user-avatar {
      width: 40px;
      height: 40px;
      margin-left: 18px;
    }
  }
  .message-list {
    background-color: #fcfbfb;
    .message-list-search-input {
      background: #efefef;
      border-radius: 6px;
      width: 100%;
      height: 38px;
    }
    .message-list-item-text {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: normal;
      max-height: 1.7rem;
    }
  }
  .message-chat {
    .message-chat-header {
      height: 60px;
      background-color: #f4f4f4;
    }
    .message-chat-inner {
      position: relative;
      .message-box {
        z-index: 10;
        border-radius: 6px;
        .my-message {
          background: #ccccff;
        }
        .user-message {
          background: #ffffff;
        }
        .message-box-inner {
          display: inline-block;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
        }
      }
      .message-chat-bg {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: auto;
        z-index: 1;
      }
    }
    .message-chat-footer {
      background: #f4f4f4;
    }
  }
}
